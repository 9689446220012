import { combineReducers } from 'redux';
import canvasReducers from './canvasReducer';

function errors(state = { errors: '' }, action) {
    switch (action.type) {
      case 'ADD_ERROR':
        return { ...state, errors: { type: 'error', message: action.payload } };
  
      case 'ADD_SUCCESS':
        return { ...state, errors: { type: 'success', message: action.payload } };
  
      case 'REMOVE_ERROR':
        return { ...state, errors: '' };
  
      default:
        return state;
    }
  }
  function loading(state = { loading: false, text: '' }, action) {
    switch (action.type) {
      case 'SHOW':
        return { ...state, loading: true, text: action.text  };
  
      case 'HIDE':
        return { ...state, loading: false , text: ''};
      default:
        return state;
    }
  }

  
export default combineReducers({
    canvas:canvasReducers,
    errors:errors,
    loading:loading
});
