import React, {Fragment} from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {addTriAngle, addCircle} from '../../actions/index';

const Texts = (props) => {
    return (
      <Fragment>

        <button onClick={props.addCircle}>Add Circle</button>
        <button onClick={props.addTriAngle}>Add TriAngle</button>
      </Fragment>);
    }

    function mapStateToProps(state) {
        return {
            videos: state.canvas.videos,
        }
    }

    function mapDispatchToProps(dispatch) {
        return bindActionCreators(
            {
                addTriAngle: addTriAngle,
                addCircle:addCircle
            },
            dispatch
        )
    }
    export default connect(mapStateToProps, mapDispatchToProps)(Texts)
