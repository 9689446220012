import { config } from "./config";
//import { authHeader } from "../helpers";
import axios from "axios";

const login = async (email, password) => {
  try {
    let body = { email, password };
    const resp = await axios.post(`${config.apiUrl}/login`, body);
    console.log(resp.data);
    return resp.data;
  } catch (err) {
    // Handle Error
    console.error(err);
    throw err;
  }
};

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
}

export const userService = {
  login,
  logout,
};
