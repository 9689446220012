import DesignToolLayout from './containers/DesignToolLayout'
import Canvas from './components/Canvas/index'
import './assets/css/all-scss-files.css'
import SidebarLeft from './components/SidebarLeft/index'
import SidebarRight from './components/SidebarRight/index'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import reducers from './reducers';
import logger from 'redux-logger';
import './assets/scss/index.scss';

let middleware = applyMiddleware(thunk);
if (process.env.NODE_ENV !== 'production') {
  middleware = applyMiddleware(thunk, logger);
}
const store = createStore(reducers, middleware);

const Configurator = (props) => {
    return (
        <div>
           <Provider store={store}>
            <DesignToolLayout>
                <div className="wrapper-level-2">
                    <ul className="d-flex list-inline bb-working-area mb-0">
                        <SidebarLeft />
                        <Canvas  options={{
                            width:5.5,
                            height:2.5,
                            side_name:"Front",
                            name:"Business Card"
                        }}/>
                        <SidebarRight />
                    </ul>
                </div>
            </DesignToolLayout>
            </Provider>
        </div>
    )
}

export default Configurator
