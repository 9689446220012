import fabric from '../fabric'

window.selectedCanvas = null

const productList = [
    {
        name: 'Teardrop flag',
        id: 'flag',
        sizes: [
            {
                width: 2.5,
                height: 5.42,
                overlay: 'teardrop banner - 2.5 x 5.42 -SF.svg',
                scene: {
                    image: 'Mockup teardrop banner - 2.5 x 5.42 -SF NEW.svg',
                },
            },
            {
                width: 2.08,
                height: 5,
                overlay: 'teardrop banner - 2.08 x 5  - SF.svg',
                scene: {
                    image: 'Mockup teardrop banner - 2.08 x 5  - SF NEW.svg',
                },
            },
            {
                width: 3,
                height: 6.92,
                overlay: 'teardrop banner - 3 x 6.92 - SF.svg',
                scene: {
                    image: 'Mockup teardrop banner - 3 x 6.92 - SF NEW.svg',
                },
            },
            {
                width: 3.33,
                height: 7.75,
                overlay: 'teardrop banner - 3.33 x 7.75 - SF.svg',
                scene: {
                    image: 'Mockup teardrop banner - 3.33 x 7.75 - SF NEW.svg',
                },
            },
            {
                width: 4.58,
                height: 11.67,
                overlay: 'teardrop banner - 4.58 x 11.67 - SF.svg',
                scene: {
                    image: 'Mockup teardrop banner - 4.58 x 11.67 - SF NEW.svg',
                },
            },
        ],
    },
    {
        name: 'Number Plate',
        id: 'plate',
        sizes: [
            {
                width: 12,
                height: 6,
                overlay: '12 x 6 License Plate.svg',
            },
            {
                width: 8,
                height: 3,
                overlay: '12 x 6 License Plate.svg',
            },
        ],
    },
    {
        name: 'Business card ',
        id: 'bc-001',
        sizes: [
            {
                width: 2.5,
                height: 5,
            },
            {
                width: 4,
                height: 10,
            },
        ],
    },
    {
        name: 'Std Business card ',
        id: 'bc-002',
        sizes: [
            {
                width: 5,
                height: 2.5,
            },
            {
                width: 6,
                height: 3,
            },
        ],
    },
]

export default function canvasReducers(
    state = {
        product: productList[2],
        isPartiallyOnScreen: false,
        outOfPrintableArea: false,
        canvasList: [],
        selectedCanvas: null,
        videos: [],
        live: { show: false },
        productList: productList,
        previewImage: null,
        layers:[]
    },
    action
) {
    const refreshCanvas = () => {
        state.selectedCanvas?.canvas.bringToFront(
            state.selectedCanvas?.canvas._objects.find(
                (item) => item.id === 'overlay_group'
            )
        )
        state.selectedCanvas?.canvas.bringToFront(
            state.selectedCanvas?.canvas._objects.find(
                (item) => item.id === 'overlay_image'
            )
        )
        state.selectedCanvas?.canvas.bringToFront(
            state.selectedCanvas?.canvas._objects.find(
                (item) => item.id === 'red_line'
            )
        )
        state.selectedCanvas?.canvas.bringToFront(
            state.selectedCanvas?.canvas._objects.find(
                (item) => item.id === 'black_line'
            )
        )
        state.selectedCanvas?.canvas.renderAll()
    }

    switch (action.type) {
        case 'INIT':
            window.selectedCanvas = action.payload
            return {
                ...state,
                selectedCanvas: action.payload,
                canvasOptions: action.options,
            }
        case 'PRODUCT_LOAD':
            return { ...state, product: action.payload }
        case 'GET_VIDEO':
            return { ...state, videos: [...action.payload] }
        case 'POST_VIDEO':
            return { videos: [...state.videos, ...action.payload] }
        case 'PAGINATE_VIDEO':
            return { ...state, videos: [...state.videos, ...action.payload] }
        case 'SHOW_LIVE':
            return { ...state, live: { ...action.payload } }
        case 'GET_LIVE':
            return { ...state, ...state.videos.live }
        case 'PARTIAL_OUTOF_CANVAS':
            return { ...state, isPartiallyOnScreen: action.payload }
        case 'FULL_OUTOF_CANVAS':
            return { ...state, isOnScreen: action.payload }
        case 'OBJECT_OUTOF_PRINTABLE_AREA':
            return { ...state, outOfPrintableArea: action.payload }

        case 'GROUP':
            action.payload.toGroup()
            state.selectedCanvas?.canvas.requestRenderAll()
            return { ...state }
        case 'UNGROUP':
            if (action.payload.type !== 'group') {
                return null
            }
            action.payload.toActiveSelection()
            state.selectedCanvas?.canvas.requestRenderAll()
            return { ...state }
        case 'REMOVE_OBJECT':
            state.selectedCanvas?.canvas.remove(action.payload)
            refreshCanvas()
            return { ...state }
        case 'BRING_CENTER_OBJECT':
            action.payload.center()
            refreshCanvas()
            return { ...state }

        case 'ADD_RECT':
            var rect = new fabric.fabric.Rect({
                width: 200,
                height: 100,
                left: 80,
                top: 80,
                angle: 30,
                fill: 'rgba(255,0,0)',
            })
            state.selectedCanvas?.canvas.add(rect)
            refreshCanvas()
            return { ...state, selectedCanvas: state.selectedCanvas }
        case 'ADD_CIRCLE':
            state.selectedCanvas?.canvas.add(
                new fabric.fabric.Circle({
                    radius: 100,
                    left: 80,
                    top: 80,
                    opacity: 1,
                    strokeWidth: 1,
                    stroke: 'purple',
                })
            )
            refreshCanvas()
            return { ...state, selectedCanvas: state.selectedCanvas }
        case 'ADD_TRIANGLE':
            state.selectedCanvas?.canvas.add(
                new fabric.fabric.Triangle({
                    width: 80,
                    height: 80,
                    fill: 'blue',
                    left: 80,
                    top: 80,
                })
            )
            refreshCanvas()
            return { ...state, selectedCanvas: state.selectedCanvas }
        case 'UPDATE_PREVIEW':
            return { ...state, previewImage: action.payload }
        case 'UPDATE_LAYERS':
            let objectsList = []
                if (
                    typeof state.selectedCanvas !=='undefined' &&
                    typeof state.selectedCanvas?.canvas !=='undefined'
                ){
                    objectsList = state.selectedCanvas?.canvas.getObjects().filter(
                        (item) => {
                            return (
                                item.id !=='overlay_image' &&
                                item.id !=='black_line' &&
                                item.id !=='red_line'
                            )
                        }
                    )
            
                }

            return { ...state, layers:objectsList}
        case 'EXPORT_SVG':
            state.selectedCanvas?.canvas.clone(
                (clonedCanvas) => {
                    //            let clonedCanvas = state.selectedCanvas?.canvas
                    //clonedCanvas  =state.selectedCanvas.canvas
                    let overlayGroup = clonedCanvas._objects.find(
                        (item) => item.id === 'overlay_group'
                    )
                    if (overlayGroup) {
                        //overlayGroup.ungroupOnCanvas()
                        //  var destroyedGroup = overlayGroup.destroy();
                        var items = overlayGroup.getObjects()

                        clonedCanvas.remove(overlayGroup)

                        items.forEach(function (item) {
                            //item.setCoords()
                            if (item === 'overlay_image') {
                                item.fill = 'white'
                                item.bringToFront()
                            }

                            clonedCanvas.add(item)
                        })

                        let overlayRect = clonedCanvas._objects.find(
                            (item) => item.id === 'overlay_image'
                        )
                        if (overlayRect) {
                            overlayRect.fill = 'white'
                            // var bg = new fabric.fabric.Image.fromURL('/overlays/pug.jpg',function(item){
                            //     overlayRect = item
                            //  })

                            overlayRect.bringToFront()
                        }
                        clonedCanvas.renderAll()
                        let overlayes = clonedCanvas._objects.filter(
                            (item) =>
                                item.id === 'overlay_image' ||
                                item.id === 'black_line' ||
                                item.id === 'red_line'
                        )
                        let newGroup = new fabric.fabric.Group(overlayes, {
                            ...overlayGroup.toObject(),
                        })
                        newGroup.scaleX = clonedCanvas.width / newGroup.width
                        newGroup.scaleY = clonedCanvas.height / newGroup.height
                        newGroup.id = 'overlay_group'
                        newGroup.selectable = false
                        newGroup.isControlVisible = false
                        newGroup.hasControls = false
                        newGroup.lockMovementX = true
                        newGroup.lockMovementY = true
                        newGroup.evented = false
                        clonedCanvas.add(newGroup)
                        overlayes.map((item) => clonedCanvas.remove(item))
                        clonedCanvas.renderAll()
                    }

                    let canvasToImage = clonedCanvas.toDataURL('png')
                    clonedCanvas.clear()
                    clonedCanvas.renderAll()
                    new fabric.fabric.util.loadImage(
                        canvasToImage,
                        function (img) {
                            let imgObj = new fabric.fabric.Image(img, {
                                left: 0,
                                top: 0,
                                scaleX: clonedCanvas.width / img.width,
                                scaleY: clonedCanvas.height / img.height,
                            })
                            clonedCanvas.add(imgObj)
                            console.log('imgObj', imgObj)
                            clonedCanvas.renderAll()
                            console.log(
                                'clonedCanvas',
                                clonedCanvas._objects,
                                clonedCanvas.toDataURL('png')
                            )
                        }
                    )
                    //return clonedCanvas;
                },
                ['id']
            )

            return { ...state }
        case 'ADD_IMAGE':
            var FR = new FileReader()
            console.log('action.payload ', action.payload)
            FR.addEventListener('load', function (e) {
                new fabric.fabric.util.loadImage(
                    e.target.result,
                    function (img) {
                        let imgObj = new fabric.fabric.Image(img, {
                            left: 80,
                            top: 80,
                            scaleX:
                                state.selectedCanvas?.canvas.width / img.width,
                            scaleY:
                                state.selectedCanvas?.canvas.height /
                                img.height,
                        })
                        state.selectedCanvas?.canvas.add(imgObj)
                        refreshCanvas()
                    }
                )
            })
            FR.readAsDataURL(action.payload)
            return { ...state, selectedCanvas: state.selectedCanvas }
        case 'ADD_SVG_TEMPLATE':
            state.selectedCanvas?.canvas.clean()
            var reader = new FileReader()
            reader.onload = function (e) {
                var svgData = e.target.result
                var parser = new DOMParser()
                var doc = parser.parseFromString(svgData, 'image/svg+xml')
                //             svgData = svgData.replace(/http:\/\//g, 'https://')

                function getChildrens(group, options, objects) {
                    let list = []
                    for (let i = 0; i < group.children.length; i++) {
                        if (
                            group.children[i].tagName == 'g' &&
                            typeof group.children[i].children != 'undefined' &&
                            group.children[i].children.length > 0
                        ) {
                            list.push(
                                getChildrens(
                                    group.children[i],
                                    options,
                                    objects
                                )
                            )
                        } else {
                            //adding Image from actual objects in same position based on href comparision
                            if (group.children[i].tagName == 'image') {
                                let imgs = objects.filter((item) => {
                                    return (
                                        typeof item['xlink:href'] !=
                                            'undefined' &&
                                        typeof group.children[i].href !=
                                            'undefined' &&
                                        item['xlink:href'] ==
                                            group.children[i].href.baseVal
                                    )
                                })

                                list.push(imgs[0])
                            } else {
                                fabric.fabric.parseElements(
                                    [group.children[i]],
                                    function (instances, elements) {
                                        if (
                                            typeof instances[0] !=
                                                'undefined' &&
                                            instances[0].id != 'BG'
                                        ) {
                                            list.push(instances[0])
                                        } else {
                                            //list.push(group.children[i])
                                            let imgs = objects.filter((item) => { return item.id == group.children[i].id})
                                            console.log('imgs', objects)
                                            console.log(
                                                'instances skipped ',
                                                group.children[i],
                                                instances
                                            )
                                        }
                                    },
                                    options
                                )
                            }
                        }
                    }
                    let obj = new fabric.fabric.Group(list)

                    return obj
                }
                function buildGroupTogether(svgObject, options, objects,elements) {
                    console.log('svgObject', svgObject)
                    let singleLevel = []
                    let i = 0
                    while (i < svgObject.length) {
                        //  svgObject[i].tagName == 'g' && typeof svgObject[i].id != 'undefined' && svgObject[i].id != ''
                        if (
                            svgObject[i].tagName == 'g' &&
                            svgObject[i].parentNode.tagName == 'svg'
                        ) {
                            singleLevel.push(
                                getChildrens(svgObject[i], options, objects)
                            )
                        } else if (svgObject[i].parentNode.tagName == 'svg' && svgObject[i].id != 'BG') {
                            // These are single text removed due to duplicates
                            fabric.fabric.parseElements(
                                [svgObject[i]],
                                function (instances, elements) {
                                    console.log('instancesdddd', instances)
                                    if (typeof instances[0] != 'undefined') {
                                        singleLevel.push(instances[0])
                                    }
                                },
                                options
                            )
                        }
                        i++
                    }
                    return singleLevel
                }
                svgData = svgData.replace('x="0px"', '')
                svgData = svgData.replace('y="0px"', '')
                new fabric.fabric.loadSVGFromString(
                    svgData,
                    async function (objects, options, elements, svgObject) {
                     
                        //     let groups = {}
                        // console.log(' options, elements, svgObject',elements,  options)
                        // console.log('--', elements[1])
                        // fabric.fabric.parseElements([elements[1],elements[2]], function(instances, elements) {
                        //     console.log('instances', instances)
                        // }, options)
                        let parent = null
                        // elements.map(ele=>{
                        //     if(ele.parentNode &&  ele.parentNode.tagName == 'g' &&  typeof ele.parentNode.id != 'undefined' && ele.parentNode.id != '' && ele.parentNode != parent)
                        //     {
                        //         parent = ele.parentNode
                        //         console.log('parent' , parent)
                        //     }else if (ele.parentNode == parent){
                        //         console.log(ele.parentNode)
                        //     }
                        // })

                        // console.log('svgObject', svgObject)
                        // svgObject.map(item=>{
                        //     console.log('item', item.parentNode.tagName)
                        // })

                        var parser = new fabric.fabric.window.DOMParser(),
                            doc = parser.parseFromString(svgData.trim(), 'text/xml');

                        console.log('options.svgUid', options.svgUid, doc.documentElement)
                        var svgUid =  fabric.fabric.Object.__uid++, i, len;
                        options.svgUid = svgUid;

                        fabric.fabric.gradientDefs[svgUid] = fabric.fabric.getGradientDefs(doc.documentElement);
                        fabric.fabric.cssRules[svgUid] = fabric.fabric.getCSSRules(doc.documentElement);
                        fabric.fabric.clipPaths[svgUid] = fabric.fabric.clipPaths;
                        
                        let groups = buildGroupTogether(
                            svgObject,
                            options,
                            objects,
                            elements
                        )
                        delete fabric.fabric.gradientDefs[svgUid];
                        delete fabric.fabric.cssRules[svgUid];
                        delete fabric.fabric.clipPaths[svgUid];

                        console.log('groups', groups)
                        // add background which is missing  - removed due to duplicates
                        let mainObj = objects.filter((item) => {
                            return item.id == 'BG'
                        })
                        //let mainObj = []
                        //    // console.log("finalelements",finalelements)
                        //groups.join(mainObj)
                        mainObj = [...mainObj, ...groups]
                        console.log('mainObj', mainObj)
                        objects = await state.selectedCanvas?.canvas.parseObjects(
                            mainObj,
                            elements
                        )
                        //    // console.log('---------',  svgObject)
                        //     elements.map((item, i) => {

                        //         // new fabric.fabric.parseElements([item],function(obj){
                        //         //     console.log("element",obj[0].id, obj[0] )
                        //         // })

                        //         if (
                        //             typeof item.parentNode.id !=='undefined' &&
                        //             item.parentNode.id
                        //         ) {
                        //             if (
                        //                 typeof groups[item.parentNode.id] ==
                        //                 'undefined'
                        //             ) {
                        //                 groups[item.parentNode.id] = []
                        //             }
                        //             groups[item.parentNode.id].push(objects[i])
                        //         }
                        //     })

                        //old
                        var obj = new fabric.fabric.util.groupSVGElements(
                            objects,
                            options
                        )

                        obj.set({
                            left: 0,
                            top: 0,
                            scaleX:
                                state.selectedCanvas?.canvas.width / obj.width,
                            scaleY:
                                state.selectedCanvas?.canvas.height /
                                obj.height,
                        })
                        let clubbedObjects = {}
                        // obj.getObjects().map((item) => {
                        //     if (typeof item.id != 'undefined' && item.id) {
                        //         if (
                        //             typeof clubbedObjects[item.id] ==
                        //             'undefined'
                        //         ) {
                        //             clubbedObjects[item.id] = []
                        //         }
                        //         clubbedObjects[item.id].push(item)
                        //     } else {
                        //         let id = 'id_' + Math.floor(Math.random() * 100)
                        //         clubbedObjects[id] = item
                        //     }
                        // })

                        state.selectedCanvas?.canvas.add(obj).renderAll()

                        obj.toActiveSelection()
                        state.selectedCanvas?.canvas.requestRenderAll()
                        state.selectedCanvas?.canvas._discardActiveObject()

                        // let indexKeysArray = Object.keys(clubbedObjects)
                        // indexKeysArray.map((key) => {
                        //     if (key && key !== '') {
                        //         let singleGroup = []
                        //         objects.map((item, index) => {
                        //             if (key === item.id) {
                        //                 //singleGroup.push(item)
                        //                 state.selectedCanvas?.canvas.remove(
                        //                     item
                        //                 )
                        //             }
                        //         })
                        //         singleGroup = clubbedObjects[key]
                        //         // let removalIndex = 0
                        //         // state.selectedCanvas?.canvas
                        //         //     .getObjects()
                        //         //     .map((removeItem, removedIndex) => {
                        //         //         if (
                        //         //             indexKeysArray.indexOf(
                        //         //                 removeItem.id
                        //         //             ) !== -1
                        //         //         ) {
                        //         //             if (!removalIndex) {
                        //         //                 removalIndex = removedIndex
                        //         //             }
                        //         //         }
                        //         //     })
                        //         state.selectedCanvas?.canvas.renderAll()

                        //         let groupedListOfelemnts = new fabric.fabric.util.groupSVGElements(
                        //             singleGroup
                        //         )
                        //         state.selectedCanvas?.canvas
                        //             .add(groupedListOfelemnts)
                        //             .renderAll()
                        //         groupedListOfelemnts.setCoords()
                        //         // state.selectedCanvas?.canvas.moveToLayer(
                        //         //     groupedListOfelemnts,
                        //         //     removalIndex
                        //         // )

                        //         state.selectedCanvas?.canvas.renderAll()
                        //     }
                        // })

                        state.selectedCanvas?.canvas
                            .getObjects()
                            .map((item) => {
                                if (!item.isOnScreen()) {
                                    state.selectedCanvas?.canvas.remove(item)
                                    state.selectedCanvas?.canvas.renderAll()
                                }
                            })
                        let background = state.selectedCanvas?.canvas.findObjectById(
                            'BG'
                        )

                        if (background) {
                            state.selectedCanvas?.canvas.setAsBackground(
                                background
                            )
                        }
                        refreshCanvas()
                    }
                )
            }
            if (action.payload) {
                reader.readAsText(action.payload)
            } else {
                var request = new XMLHttpRequest()
                request.open('GET', '/test/svg_front_1617479-AI.svg', true)
                request.responseType = 'blob'
                request.onload = function () {
                    reader.readAsText(request.response)
                }
                request.send()
            }
            return { ...state, selectedCanvas:state.selectedCanvas }
    }
    return state
}
