import React, {Fragment} from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { changeProduct} from '../../actions/index';

const Products = (props) => {
    return (
      <Fragment>
          {props.productList?props.productList.map((item,index)=>{
              return (<button key={index} onClick={()=>props.changeProduct(item)}>{item.name}</button>)
          }):null}
      
      </Fragment>);
    }

    function mapStateToProps(state) {
        return {
            videos: state.canvas.videos,
            productList : state.canvas.productList
        }
    }

    function mapDispatchToProps(dispatch) {
        return bindActionCreators(
            {
                
                changeProduct:changeProduct
            },
            dispatch
        )
    }
    export default connect(mapStateToProps, mapDispatchToProps)(Products)
