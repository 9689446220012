import React, {Fragment} from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { addImage, uploadSvgTemplate} from '../../actions/index';

const Uploads = (props) => {

    const uploadImage = (element)=>{
        let file = element.files[0]
        props.addImage(file)    
    }

    const uploadSvgTemplate = (element)=>{
        let file = element.files[0]
        props.uploadSvgTemplate(file)    
    }

    return (
      <Fragment>
        <label>Upload Image <input type="file" id="upload_image" onChange={(e)=>{uploadImage(e.target)}}></input></label>
        <label>Upload Overlay <input type="file" id="upload_overlay"></input></label>
        <label>Upload SVG Template <input type="file" id="upload_template" onChange={(e)=>{uploadSvgTemplate(e.target)}}></input></label>

      </Fragment>);
    }

    function mapStateToProps(state) {
        return {
            videos: state.canvas.videos,
        }
    }

    function mapDispatchToProps(dispatch) {
        return bindActionCreators(
            {
               addImage: addImage,
               uploadSvgTemplate:uploadSvgTemplate
            },
            dispatch
        )
    }
    export default connect(mapStateToProps, mapDispatchToProps)(Uploads)
