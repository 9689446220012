import fabric from 'fabric'
fabric.fabric.Object.NUM_FRACTION_DIGITS = 8

fabric.fabric.Object.prototype.getZIndex = function () {
    return this.canvas.getObjects().indexOf(this)
}

fabric.fabric.Canvas.prototype.moveToLayer = function (object, position) {
    while (object.getZIndex() > position) {
        this.sendBackwards(object)
    }
}

fabric.fabric.Canvas.prototype.findObjectById = function (id) {
    let item = this._objects.filter((item) => item.id === id)
    return item[0]
}

fabric.fabric.Canvas.prototype.setAsBackground = function (item) {
    if (item.type === 'Image') {
        return this.setBackgroundImage(item, this.renderAll.bind(this))
    } else {
        item.set({
            scaleX: this.width / item.width,
            scaleY: this.height / item.height,
        })

        this.sendToBack(item)
        item.selectable = false
        item.isControlVisible = false
        item.hasControls = false
        item.lockMovementX = true
        item.lockMovementY = true
        item.evented = false
        this.renderAll()
    }
}
fabric.fabric.Canvas.prototype.clean = function () {
    let safeObjects = [
        'overlay_group',
        'overlay_image',
        'red_line',
        'black_line',
    ]
    console.log("this._objects",this.getObjects())
    this.getObjects().map((item) => {
        if (safeObjects.indexOf(item.id) == -1) {
            console.log("item",item)
            this.remove(item)
        }
    })
    this.renderAll()
}
fabric.fabric.Canvas.prototype.parseObjects = function (objects, elements) {
    return objects.map((obj, index) => {
        if (obj['type'] === 'text') {
            var element = elements[index]
            var childrens = [].slice.call(element.childNodes)
            var value = ''
            let firstTextObject = null
            let textStyle = null
            let textAlign = 'left'
            let hasTspan = childrens.find((item) => item.nodeName == 'tspan')
            if (childrens.length > 0 && hasTspan) {
                childrens.forEach(function (el, index, array) {
                    if (el.nodeName === 'tspan') {
                        value += el.childNodes[0].nodeValue
                        firstTextObject = el.childNodes[0]
                        textStyle = el.style
                    } else if (el.nodeName === '#text') {
                        value += el.nodeValue
                        firstTextObject = el
                        textStyle = el.style
                    }

                    if (index < childrens.length - 1) {
                        value += '\n'
                    }
                    if (
                        textAlign == 'left' &&
                        typeof el.x != 'undefined' &&
                        el.x.baseVal[0].value === 0 &&
                        el.y.baseVal[0].value === 0
                    ) {
                        textAlign = 'center'
                    }
                })
                value =
                    obj['text-transform'] === 'uppercase'
                        ? value.toUpperCase()
                        : value

                let objectOptions = obj.toObject()
                var text = new fabric.fabric.IText(obj.text, {
                    top: objectOptions.top,
                })
                text.set({
                    text: value,
                    type: 'i-text',
                })
                if (typeof textStyle !== 'undefined' && textStyle.fontFamily) {
                    text.set({
                        fontFamily: textStyle.fontFamily,
                    })
                }

                var left = 0
                var _textAlign = obj.get('textAnchor')
                    ? obj.get('textAnchor')
                    : textAlign
                switch (_textAlign) {
                    // case "center":
                    //     left = obj.left - text.getScaledWidth() / 2;
                    //     break;
                    case 'right':
                        left = obj.left - text.getScaledWidth()
                        break
                    default:
                        left = obj.left
                        break
                }

                text.set({
                    left: left,
                    textAlign: _textAlign,
                })
                return text
            } else {
                return obj
            }
        } else {
            return obj
        }
    })
}

export default fabric
