import React, { Fragment} from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
    addTriAngle,
    getObjects,
    removeObject,
    bringBackToCenter,
} from '../../actions/index'

const Layers = (props) => {
    const getObjects = () => {
        let objectsList = []
        if (
            typeof props.canvas.selectedCanvas !=='undefined' &&
            typeof props.canvas.selectedCanvas?.canvas !=='undefined'
        ){
            objectsList = props.canvas.selectedCanvas?.canvas.getObjects().filter(
                (item) => {
                    return (
                        item.id !=='overlay_image' &&
                        item.id !=='black_line' &&
                        item.id !=='red_line'
                    )
                }
            )
        }
        return objectsList
    }

console.log("-------------------", props.canvas.selectedCanvas?.canvas.getObjects())

    return (
        <Fragment>
            {getObjects().map((item, index) => {
                if(item.isOnScreen()){
                return (
                    <div key={index} className="row">
                        <div className="col-md-4 mt-2">
                            <img
                                src={item.toDataURL()}
                                width="40"
                                height="30"
                                alt="logo"
                            ></img>
                        </div>
                        <div className="col-md-4  mt-2">{item.type}</div>
                        <div className="col-md-4  mt-2">
                            <button onClick={() => props.removeObject(item)}>
                                {' '}
                                Remove
                            </button>
                           
                        </div>
                    </div>
                )
                }
            })}
            {/* <button onClick={props.addTriAngle}>Add TriAngle</button> */}
        </Fragment>
    )
}

function mapStateToProps(state) {
    return {
        videos: state.canvas.videos,
        canvas: state.canvas,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            addTriAngle: addTriAngle,
            getObjects: getObjects,
            removeObject: removeObject,
            bringBackToCenter: bringBackToCenter,
        },
        dispatch
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(Layers)
