import React, { Fragment} from 'react'
import { connect } from 'react-redux'
import { canvasInit, addTriAngle, generatePriveiew, uploadSvgTemplate } from '../../actions/index'
import { bindActionCreators } from 'redux'

import fabric from '../../fabric'
  
  class Canvas extends React.Component {
    constructor(props) {
        super(props)
        this.imagesizer = React.createRef()
        this.state = {
            size:null,
            sizes: [],
            editor: null,
            showPriview: true,
        }
    }
    sizeChange(size) {
        size = JSON.parse(size)
        this.setState({ size: size }, () => {
            this.loadProduct(this.props.canvas.product, this.state.size)
            this.props.generatePriveiew(this.props.canvas)
        })
    }
    loadProduct(product, size = null) {
        if (this.state.editor) {
            this.state.editor.dispose()
        }
       return new Promise(( resolve, reject)=>{
           try{
                this.setState({editor:new fabric.fabric.Canvas('canvas')},()=>{
                    let options = {
                        container: this.imagesizer,
                    }
                
                    if(size){
                        options.width = size.width
                        options.height = size.height
                        options.overlay =
                        (size.overlay)? `/overlays/` + size.overlay:null
                        options.scene  = size.scene
            
                        this.props.canvasInit(
                            { canvas: this.state.editor, store: this.props.canvas },
                            options
                        )
                        resolve()
                    }
                })
            }catch(e){
                reject(e)
            }
       })
       

        
    }
    componentDidMount() {
        this.setState({sizes:this.props.canvas.product})
        this.setState({ size: this.props.canvas.product? this.props.canvas.product.sizes[0]:{} }, () => {
            this.loadProduct(this.props.canvas.product, this.state.size).then(()=>{
                 // this.props.uploadSvgTemplate()
            })
           // this.props.uploadSvgTemplate()
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.canvas.product !== this.props.canvas.product) {
            this.setState({sizes:this.props.canvas.product.sizes})
            this.setState({ size: this.props.canvas.product.sizes[0] }, () => {
                this.loadProduct(this.props.canvas.product, this.state.size)
            });
        }
    }

    

    render() {
        return (
            <Fragment>
                <label style={{ position: 'absolute', left: '300px' }}>
                    {' '}
                    Show Priview{' '}
                    <input
                        type="checkbox"
                        defaultChecked={this.state.showPriview}
                        onChange={(e) => {
                            this.setState({
                                showPriview: !this.state.showPriview,
                            })
                        }}
                    ></input>{' '}
                </label>
                <li className="bb-col-3">
                    <div
                        className="bb-center-working-area"
                        ref={this.imagesizer}
                    >
                        <canvas id="canvas"></canvas>
                        {this.state.showPriview ? (
                            
                            <div style={{ marginLeft: '30px' }}>                            
                                <div id="preview" >
                                    <canvas id="preview_canvas"></canvas>
                                </div>
                            </div>
                            
                        ) : null}
                    </div>
                </li>
                <h6
                    style={{
                        position: 'absolute',
                        float: 'right',
                        left: '50%',
                        
                    }}
                >
                    {this.props.canvas.isPartiallyOnScreen
                        ? 'Partieal out of canvas '
                        : ''}
                    {this.props.canvas.selectedCanvas?.canvas._activeObject &&
                    !this.props.canvas.isOnScreen
                        ? 'Full out of canvas '
                        : ''}
                    {this.props.canvas.outOfPrintableArea
                        ? 'Out of printable area'
                        : null}
                </h6>
                <select
                    style={{
                        position: 'absolute',
                        float: 'right',
                        left: '40%',
                    }}
                    value={JSON.stringify(this.state.size)}
                    onChange={(e) => {
                        this.sizeChange(e.target.value)
                    }}
                >
                   {this.props.canvas.product && this.props.canvas.product.sizes?this.props.canvas.product.sizes.map((item,index)=>{

                       return (<option
                        key={index}
                        value={JSON.stringify({
                            width: item.width,
                            height: item.height,
                            overlay: item.overlay,
                            scene: item.scene
                        })}
                    >
                        {item.width}x{item.height}
                    </option>)
                   }):null} 
                    
                </select>
                        

            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        videos: state.canvas.videos,
        canvas: state.canvas,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            canvasInit: canvasInit,
            addTriAngle: addTriAngle,
            generatePriveiew:generatePriveiew,
            uploadSvgTemplate: uploadSvgTemplate
        },
        dispatch
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(Canvas)
