import React, { Fragment } from 'react'
import Shapes from '../Shapes/index';
import Layers from '../Layers/index'
import Uploads from '../Uploads/index'
import Texts from '../Texts/index'
import Products from '../Products/index'


const SidebarLeft = (props) => {
    return (
        <Fragment>
            <li className="bb-col-1 bb-left-icon-area">
                <ul
                    className="nav flex-column nav-pills bb-icons-used"
                    id="pills-tab"
                    role="tablist"
                >
                    <li className="nav-item icon-templates" role="presentation">
                        <a
                            className="nav-link active"
                            id="icon-templates-tab"
                            data-toggle="pill"
                            href="#icon-templates"
                            role="tab"
                            aria-controls="icon-templates"
                            aria-selected="true"
                        >
                            <svg
                                className="bb-common-icon ic_Template"
                                id="ic_Template"
                                xmlns="http://www.w3.org/2000/svg"
                                width="36"
                                height="36"
                                viewBox="0 0 36 36"
                            >
                                {' '}
                                <rect
                                    id="Rectangle_1283"
                                    data-name="Rectangle 1283"
                                    width="36"
                                    height="36"
                                    fill="#dfdfdf"
                                    opacity="0"
                                />{' '}
                                <g
                                    id="tempaltes"
                                    transform="translate(6 6)"
                                    opacity="1"
                                >
                                    {' '}
                                    <g
                                        id="canvas_size"
                                        data-name="canvas size"
                                        transform="translate(0 0)"
                                    >
                                        {' '}
                                        <path
                                            id="Path_99"
                                            data-name="Path 99"
                                            d="M11.109,24H5.625A5.631,5.631,0,0,1,0,18.375V5.625A5.631,5.631,0,0,1,5.625,0H14.25a5.631,5.631,0,0,1,5.625,5.625V7.031a.938.938,0,1,1-1.875,0V5.625a3.754,3.754,0,0,0-3.75-3.75H5.625a3.754,3.754,0,0,0-3.75,3.75v12.75a3.754,3.754,0,0,0,3.75,3.75h5.484a.937.937,0,1,1,0,1.875ZM16.078,8.625a.938.938,0,0,0-.938-.938H13.547A1.408,1.408,0,0,1,12.14,6.281V4.687a.938.938,0,0,0-1.875,0V6.281a3.285,3.285,0,0,0,3.281,3.281H15.14A.937.937,0,0,0,16.078,8.625ZM23.287,21.3a.937.937,0,0,0-.225-1.847h-.62l-1.584-3.461c.026-.024.051-.048.076-.073a2.757,2.757,0,0,0,.81-1.968v-.03a2.814,2.814,0,0,0-1.875-2.651v-.488a.938.938,0,0,0-1.875,0v.492a2.811,2.811,0,0,0-1.014.641,2.883,2.883,0,0,0-.861,2.045,2.768,2.768,0,0,0,.9,2.039l-1.557,3.455h-.645a.938.938,0,0,0-.191,1.855L14,22.677a.938.938,0,1,0,1.709.77l.955-2.12h4.568l.972,2.124a.937.937,0,1,0,1.7-.78Zm-4.994-8.047a.911.911,0,0,1,.64-.268h.017a.942.942,0,0,1,.919.937v.034a.9.9,0,0,1-.9.9h-.074a.9.9,0,0,1-.9-.9,1,1,0,0,1,.3-.706Zm-.779,6.2,1.227-2.723c.051,0,.148,0,.2,0s.142,0,.2-.005l1.246,2.724Z"
                                            transform="translate(0 0)"
                                        />{' '}
                                    </g>{' '}
                                    <path
                                        id="Path_1326"
                                        data-name="Path 1326"
                                        d="M7.126,0H.694A.694.694,0,0,0,0,.694V6.917a.694.694,0,0,0,.694.694H7.126a.694.694,0,0,0,.694-.694V.694A.694.694,0,0,0,7.126,0ZM6.433,6.223H1.387V1.387H6.433Z"
                                        transform="translate(3.752 19.712) rotate(-90)"
                                    />{' '}
                                    <path
                                        id="Path_1327"
                                        data-name="Path 1327"
                                        d="M4.4,0H.694A.694.694,0,0,0,0,.694V4.4a.694.694,0,0,0,.694.694H4.4A.694.694,0,0,0,5.092,4.4V.694A.694.694,0,0,0,4.4,0ZM3.7,3.7H1.387V1.387H3.7Z"
                                        transform="translate(3.752 9.498) rotate(-90)"
                                    />{' '}
                                </g>{' '}
                            </svg>
                            <span className="icon-title">Templates</span>
                        </a>
                    </li>
                    <li className="nav-item icon-text" role="presentation">
                        <a
                            className="nav-link"
                            id="icon-text-tab"
                            data-toggle="pill"
                            href="#icon-text"
                            role="tab"
                            aria-controls="icon-text"
                            aria-selected="false"
                        >
                            <svg
                                className="bb-common-icon ic_Text"
                                id="ic_Text"
                                xmlns="http://www.w3.org/2000/svg"
                                width="36"
                                height="36"
                                viewBox="0 0 36 36"
                            >
                                {' '}
                                <rect
                                    id="Rectangle_1284"
                                    data-name="Rectangle 1284"
                                    width="36"
                                    height="36"
                                    fill="#dfdfdf"
                                    opacity="0"
                                />{' '}
                                <g
                                    id="Text"
                                    transform="translate(13 15)"
                                    opacity="1"
                                >
                                    {' '}
                                    <path
                                        id="Path_98"
                                        data-name="Path 98"
                                        d="M20.578,24a3.4,3.4,0,0,1-2.42-1L15.371,20.21a6.759,6.759,0,0,1-1.854-3.465l-.531-2.656a.938.938,0,0,1,1.1-1.1l2.656.531A6.758,6.758,0,0,1,20.21,15.37L23,18.158A3.422,3.422,0,0,1,20.578,24ZM15.1,15.1l.255,1.277A4.889,4.889,0,0,0,16.7,18.884l2.788,2.788a1.547,1.547,0,0,0,2.188-2.188L18.884,16.7a4.89,4.89,0,0,0-2.507-1.342Zm-.5,7.833A.937.937,0,1,0,13.284,21.6a1.828,1.828,0,0,1-3.112-1.3V9.328a3.707,3.707,0,0,0-3.7-3.7H3.75a1.875,1.875,0,0,1,0-3.75h16.5a1.875,1.875,0,1,1,0,3.75H17.531a3.707,3.707,0,0,0-3.7,3.7v1.031a.938.938,0,1,0,1.875,0V9.328A1.83,1.83,0,0,1,17.531,7.5H20.25a3.75,3.75,0,1,0,0-7.5H3.75a3.75,3.75,0,0,0,0,7.5H6.469A1.83,1.83,0,0,1,8.3,9.328V20.3a3.7,3.7,0,0,0,6.3,2.636Z"
                                        transform="translate(-6 -9)"
                                    />{' '}
                                </g>{' '}
                            </svg>
                            <span className="icon-title">Text</span>
                        </a>
                    </li>
                    <li className="nav-item icon-shapes" role="presentation">
                        <a
                            className="nav-link"
                            id="icon-shapes-tab"
                            data-toggle="pill"
                            href="#icon-shapes"
                            role="tab"
                            aria-controls="icon-shapes"
                            aria-selected="false"
                        >
                            <svg
                                className="bb-common-icon ic_Shapes"
                                id="ic_Shapes"
                                xmlns="http://www.w3.org/2000/svg"
                                width="36"
                                height="36"
                                viewBox="0 0 36 36"
                            >
                                {' '}
                                <rect
                                    id="Rectangle_1286"
                                    data-name="Rectangle 1286"
                                    width="36"
                                    height="36"
                                    fill="#dfdfdf"
                                    opacity="0"
                                />{' '}
                                <g
                                    id="shapes"
                                    transform="translate(6 6)"
                                    opacity="1"
                                >
                                    {' '}
                                    <g id="sahpes" transform="translate(0 0)">
                                        {' '}
                                        <path
                                            id="Path_1319"
                                            data-name="Path 1319"
                                            d="M18.375,24H13.031a5.631,5.631,0,0,1-5.625-5.625V13.031a5.631,5.631,0,0,1,5.625-5.625h5.344A5.631,5.631,0,0,1,24,13.031v5.344A5.631,5.631,0,0,1,18.375,24ZM13.031,9.281a3.754,3.754,0,0,0-3.75,3.75v5.344a3.754,3.754,0,0,0,3.75,3.75h5.344a3.754,3.754,0,0,0,3.75-3.75V13.031a3.754,3.754,0,0,0-3.75-3.75ZM5.4,17.439a.938.938,0,0,0-.221-1.307A7.852,7.852,0,1,1,16.161,5.226.937.937,0,1,0,17.7,4.149,9.727,9.727,0,1,0,4.1,17.66.937.937,0,0,0,5.4,17.439Z"
                                            transform="translate(0 0)"
                                        />{' '}
                                    </g>{' '}
                                    <path
                                        id="chat-heart"
                                        d="M619.875,171.973a2.233,2.233,0,0,1-1.34-.443A5.521,5.521,0,0,1,616,167.309v-.014a4.184,4.184,0,0,1,7.75,0s0,.01,0,.014a5.518,5.518,0,0,1-2.535,4.221A2.233,2.233,0,0,1,619.875,171.973Zm-2.466-4.684a4.311,4.311,0,0,0,1.971,3.114.83.83,0,0,0,.99,0,4.311,4.311,0,0,0,1.971-3.114.881.881,0,0,0-1.761,0,.7.7,0,0,1-1.409,0,.881.881,0,0,0-1.761,0Z"
                                        transform="translate(-604 -152.5)"
                                    />{' '}
                                </g>{' '}
                            </svg>
                            <span className="icon-title">Shapes</span>
                        </a>
                    </li>
                    <li className="nav-item icon-clipart" role="presentation">
                        <a
                            className="nav-link"
                            id="icon-clipart-tab"
                            data-toggle="pill"
                            href="#icon-clipart"
                            role="tab"
                            aria-controls="icon-clipart"
                            aria-selected="false"
                        >
                            <svg
                                className="bb-common-icon ic_Cliparts"
                                id="ic_Cliparts"
                                xmlns="http://www.w3.org/2000/svg"
                                width="36"
                                height="36"
                                viewBox="0 0 36 36"
                            >
                                {' '}
                                <rect
                                    id="Rectangle_1285"
                                    data-name="Rectangle 1285"
                                    width="36"
                                    height="36"
                                    opacity="0"
                                />{' '}
                                <g
                                    id="line_tool"
                                    data-name="line tool"
                                    transform="translate(7 6)"
                                    opacity="1"
                                >
                                    {' '}
                                    <g
                                        id="Group_93"
                                        data-name="Group 93"
                                        transform="translate(0 0)"
                                    >
                                        {' '}
                                        <path
                                            id="Path_105"
                                            data-name="Path 105"
                                            d="M38.724,5.608a5.525,5.525,0,0,0-1.852-4.189A5.456,5.456,0,0,0,33.38,0a.938.938,0,1,0,0,1.875,3.7,3.7,0,0,1,3.469,3.733c0,1.231-1.024,2.425-3.043,3.549a28.706,28.706,0,0,1-5.261,2.1c-.287.09-.534.168-.72.232a19.418,19.418,0,0,0-4.564,2.141,8.774,8.774,0,0,0-2.329,2.232,5.748,5.748,0,0,0-1.052,3.311,4.054,4.054,0,0,0,1.276,3.056c1.713,1.627,4.679,1.778,5.718,1.778.208,0,.339-.006.367-.007a.938.938,0,0,0-.1-1.873c-.893.044-3.481-.1-4.7-1.26a2.217,2.217,0,0,1-.69-1.695c0-3.61,5.038-5.345,6.68-5.91.162-.056.4-.13.672-.216C31.985,12.14,38.724,10.021,38.724,5.608Z"
                                            transform="translate(-18.989)"
                                        />{' '}
                                        <path
                                            id="Path_106"
                                            data-name="Path 106"
                                            d="M5.272,5.859a7.681,7.681,0,0,0,4.985-2.136c.36-.283.7-.55,1.058-.8a.937.937,0,1,0-1.064-1.544c-.407.28-.787.579-1.154.867C7.914,3.18,6.892,3.984,5.272,3.984A3.087,3.087,0,0,1,1.944.938a.937.937,0,1,0-1.875,0,4.955,4.955,0,0,0,5.2,4.922Z"
                                            transform="translate(-0.069 0)"
                                        />{' '}
                                        <path
                                            id="Path_107"
                                            data-name="Path 107"
                                            d="M257.091,246.042h0a3.422,3.422,0,0,0-4.839,0l-4.739,4.739a6.759,6.759,0,0,0-1.854,3.465l-.531,2.656a.937.937,0,0,0,1.1,1.1l2.656-.531a6.759,6.759,0,0,0,3.465-1.854l4.739-4.74a3.422,3.422,0,0,0,0-4.839Zm-1.326,3.513-4.74,4.739a4.888,4.888,0,0,1-2.507,1.342l-1.277.255.255-1.278a4.889,4.889,0,0,1,1.341-2.507l4.74-4.739a1.547,1.547,0,1,1,2.188,2.188Z"
                                            transform="translate(-234.093 -234.024)"
                                        />{' '}
                                    </g>{' '}
                                </g>{' '}
                            </svg>
                            <span className="icon-title">Clipart</span>
                        </a>
                    </li>
                    <li
                        className="nav-item icon-background"
                        role="presentation"
                    >
                        <a
                            className="nav-link"
                            id="icon-background-tab"
                            data-toggle="pill"
                            href="#icon-background"
                            role="tab"
                            aria-controls="icon-background"
                            aria-selected="false"
                        >
                            <svg
                                className="bb-common-icon ic_Background"
                                id="ic_Background"
                                xmlns="http://www.w3.org/2000/svg"
                                width="36"
                                height="36"
                                viewBox="0 0 36 36"
                            >
                                {' '}
                                <rect
                                    id="Rectangle_1288"
                                    data-name="Rectangle 1288"
                                    width="36"
                                    height="36"
                                    opacity="0"
                                />{' '}
                                <g
                                    id="img"
                                    transform="translate(6 7)"
                                    opacity="1"
                                >
                                    {' '}
                                    <path
                                        id="Path_1320"
                                        data-name="Path 1320"
                                        d="M26.276,34.614H15.609a6.674,6.674,0,0,1-6.667-6.667V19.614a6.674,6.674,0,0,1,6.667-6.667H26.276a6.674,6.674,0,0,1,6.667,6.667v8.333A6.674,6.674,0,0,1,26.276,34.614ZM15.609,15.17a4.449,4.449,0,0,0-4.444,4.444v8.333a4.449,4.449,0,0,0,4.444,4.444H26.276a4.449,4.449,0,0,0,4.444-4.444V19.614a4.449,4.449,0,0,0-4.444-4.444Zm8.393,6a1.389,1.389,0,1,0-1.389-1.389A1.389,1.389,0,0,0,24,21.17Zm-8.7,6.655,2.734-2.87a1.662,1.662,0,0,1,2.114-.048l1.689,1.382.014.011a3.9,3.9,0,0,0,2.53.927,3.653,3.653,0,0,0,2.6-1.314l.025-.028L28.625,24a1.111,1.111,0,0,0-1.691-1.442l-1.6,1.874a1.334,1.334,0,0,1-2.093.135l-1.69-1.382-.014-.011a3.88,3.88,0,0,0-5.013.159q-.032.029-.061.06L13.7,26.292a1.111,1.111,0,1,0,1.609,1.533Z"
                                        transform="translate(-8.942 -12.947)"
                                    />{' '}
                                </g>{' '}
                            </svg>
                            <span className="icon-title">Background</span>
                        </a>
                    </li>
                    <li className="nav-item icon-uploads" role="presentation">
                        <a
                            className="nav-link"
                            id="icon-uploads-tab"
                            data-toggle="pill"
                            href="#icon-uploads"
                            role="tab"
                            aria-controls="icon-uploads"
                            aria-selected="false"
                        >
                            <svg
                                className="bb-common-icon ic_Uploads"
                                id="ic_Uploads"
                                xmlns="http://www.w3.org/2000/svg"
                                width="36"
                                height="36"
                                viewBox="0 0 36 36"
                            >
                                {' '}
                                <rect
                                    id="Rectangle_1287"
                                    data-name="Rectangle 1287"
                                    width="36"
                                    height="36"
                                    opacity="0"
                                />{' '}
                                <path
                                    id="upload2"
                                    d="M4.359,18.094a4.336,4.336,0,0,1-2.7-7.751,5.043,5.043,0,0,1,4.7-6.119A6,6,0,0,1,17.993,4.9,6.61,6.61,0,0,1,19.138,17.86a.937.937,0,0,1-.495-1.809,4.735,4.735,0,0,0-1.253-9.3c-.516,0-.692-.075-.875-.244a.937.937,0,0,1-.3-.655,4.125,4.125,0,0,0-8.194-.514.938.938,0,0,1-1.041.781,3.218,3.218,0,0,0-.395-.024,3.166,3.166,0,0,0-2.95,4.312A.938.938,0,0,1,3.2,11.576a2.461,2.461,0,0,0,1.162,4.642.937.937,0,1,1,0,1.875Zm11.672-1.078a4.453,4.453,0,1,0-4.453,4.453A4.458,4.458,0,0,0,16.031,17.016Zm-1.875,0a2.578,2.578,0,1,1-2.578-2.578A2.581,2.581,0,0,1,14.156,17.016Zm4.085,6.71a.938.938,0,0,0,0-1.326l-1.312-1.312A.938.938,0,1,0,15.6,22.413l1.313,1.313a.938.938,0,0,0,1.326,0Z"
                                    transform="translate(7 6)"
                                    opacity="1"
                                />{' '}
                            </svg>
                            <span className="icon-title">Uploads</span>
                        </a>
                    </li>
                    <li className="nav-item icon-mydesigns" role="presentation">
                        <a
                            className="nav-link"
                            id="icon-mydesigns-tab"
                            data-toggle="pill"
                            href="#icon-mydesigns"
                            role="tab"
                            aria-controls="icon-mydesigns"
                            aria-selected="false"
                        >
                            <svg
                                className="bb-common-icon ic__MyDesigns"
                                id="ic__MyDesigns"
                                xmlns="http://www.w3.org/2000/svg"
                                width="36"
                                height="36"
                                viewBox="0 0 36 36"
                            >
                                {' '}
                                <rect
                                    id="Rectangle_1290"
                                    data-name="Rectangle 1290"
                                    width="36"
                                    height="36"
                                    opacity="0"
                                />{' '}
                                <g
                                    id="my_design_ic"
                                    data-name="my design_ic"
                                    transform="translate(6 4.606)"
                                >
                                    {' '}
                                    <rect
                                        id="Rectangle_1244"
                                        data-name="Rectangle 1244"
                                        width="24"
                                        height="24"
                                        transform="translate(1 1.394)"
                                        opacity="0"
                                    />{' '}
                                    <g
                                        id="my-design"
                                        transform="translate(2.455 2.394)"
                                        opacity="1"
                                    >
                                        {' '}
                                        <path
                                            id="Path_96"
                                            data-name="Path 96"
                                            d="M16.7,18.409H5.113A5.119,5.119,0,0,1,0,13.3V6.307A5.119,5.119,0,0,1,5.114,1.193H6.136a.852.852,0,0,1,0,1.7H5.114A3.413,3.413,0,0,0,1.7,6.307V13.3A3.412,3.412,0,0,0,5.113,16.7H16.7A3.413,3.413,0,0,0,20.114,13.3V6.307A3.413,3.413,0,0,0,16.7,2.9H15.639a.852.852,0,1,1,0-1.7H16.7a5.119,5.119,0,0,1,5.114,5.114V13.3A5.119,5.119,0,0,1,16.7,18.409Zm-.852,2.557A.852.852,0,0,0,15,20.114H6.818a.852.852,0,1,0,0,1.7H15A.852.852,0,0,0,15.852,20.966ZM10.909,14.24a2.986,2.986,0,0,1-2.983-2.983V5.51a5.845,5.845,0,0,1,.985-3.253l.006-.01L10.206.37a.852.852,0,0,1,1.405,0L12.9,2.247l.006.01a5.845,5.845,0,0,1,.985,3.253v5.747A2.986,2.986,0,0,1,10.909,14.24ZM10.326,3.207a4.146,4.146,0,0,0-.7,2.3v5.747a1.278,1.278,0,0,0,2.557,0V5.51a4.146,4.146,0,0,0-.7-2.3l-.583-.849Z"
                                            transform="translate(0 0)"
                                        />{' '}
                                    </g>{' '}
                                </g>{' '}
                            </svg>
                            <span className="icon-title">My Designs</span>
                        </a>
                    </li>
                    <li className="nav-item icon-products" role="presentation">
                        <a
                            className="nav-link"
                            id="icon-products-tab"
                            data-toggle="pill"
                            href="#icon-products"
                            role="tab"
                            aria-controls="icon-products"
                            aria-selected="false"
                        >
                            <svg
                                className="bb-common-icon ic_Products"
                                id="ic_Products"
                                xmlns="http://www.w3.org/2000/svg"
                                width="36"
                                height="36"
                                viewBox="0 0 36 36"
                            >
                                {' '}
                                <rect
                                    id="Rectangle_1289"
                                    data-name="Rectangle 1289"
                                    width="36"
                                    height="36"
                                    opacity="0"
                                />{' '}
                                <g
                                    id="product_box2"
                                    data-name="product box2"
                                    transform="translate(7 7)"
                                    opacity="1"
                                >
                                    {' '}
                                    <path
                                        id="Path_1476"
                                        data-name="Path 1476"
                                        d="M12.676,6.875H5.543a.859.859,0,0,1,0-1.719h7.133a.859.859,0,1,1,0,1.719ZM17.8,20.5l2.672-2.65A5.19,5.19,0,0,0,22,14.194V5.156A5.162,5.162,0,0,0,16.844,0H7.828A5.124,5.124,0,0,0,4.2,1.5L1.525,4.145A5.19,5.19,0,0,0,0,7.806v9.038A5.162,5.162,0,0,0,5.156,22h9.016A5.124,5.124,0,0,0,17.8,20.5ZM16.844,1.719a3.441,3.441,0,0,1,3.438,3.437v9.038a3.46,3.46,0,0,1-1.017,2.441l-2.672,2.65a3.416,3.416,0,0,1-2.421,1H5.156a3.441,3.441,0,0,1-3.437-3.437V7.806A3.46,3.46,0,0,1,2.736,5.365l2.672-2.65a3.416,3.416,0,0,1,2.421-1Zm-.043,15V7.828a3.457,3.457,0,0,1,1.039-2.462L18.217,5a.859.859,0,1,0-1.2-1.231l-.377.368a5.185,5.185,0,0,0-1.558,3.693v8.887a.859.859,0,1,0,1.719,0Z"
                                        transform="translate(0 0)"
                                    />{' '}
                                </g>{' '}
                            </svg>
                            <span className="icon-title">Products</span>
                        </a>
                    </li>

                    <li
                        className="nav-item icon-layers mt-auto"
                        role="presentation"
                    >
                        <a
                            className="nav-link"
                            id="icon-layers-tab"
                            data-toggle="pill"
                            href="#icon-layers"
                            role="tab"
                            aria-controls="icon-layers"
                            aria-selected="false"
                        >
                            <svg
                                className="bb-common-icon ic_Layers"
                                id="ic_Layers"
                                xmlns="http://www.w3.org/2000/svg"
                                width="36"
                                height="36"
                                viewBox="0 0 36 36"
                            >
                                {' '}
                                <rect
                                    id="Rectangle_1292"
                                    data-name="Rectangle 1292"
                                    width="36"
                                    height="36"
                                    opacity="0"
                                />{' '}
                                <g
                                    id="Layers"
                                    transform="translate(7 7)"
                                    opacity="1"
                                >
                                    {' '}
                                    <path
                                        id="Path_1348"
                                        data-name="Path 1348"
                                        d="M11.018,21.393a6.149,6.149,0,0,1-2.909-.733L1.54,17.136a2.915,2.915,0,0,1,.015-5.148L8.14,8.505a6.15,6.15,0,0,1,5.756,0l6.585,3.483a2.915,2.915,0,0,1,.015,5.148L13.927,20.66A6.15,6.15,0,0,1,11.018,21.393Zm0-11.88a4.431,4.431,0,0,0-2.073.515L2.36,13.51a1.194,1.194,0,0,0-.006,2.109l6.569,3.523a4.423,4.423,0,0,0,4.191,0l6.569-3.523a1.194,1.194,0,0,0-.006-2.109l-6.585-3.483A4.431,4.431,0,0,0,11.018,9.512ZM8.543,9.266h0Zm5.385,19.183,7.654-4.105a.861.861,0,0,0-.814-1.517l-7.654,4.105a4.425,4.425,0,0,1-4.194,0L1.265,22.868a.861.861,0,0,0-.807,1.521l7.651,4.061a6.141,6.141,0,0,0,5.819,0Zm0-3.873,7.654-4.105a.861.861,0,1,0-.814-1.517l-7.654,4.105a4.425,4.425,0,0,1-4.194,0L1.265,18.995a.861.861,0,1,0-.807,1.521l7.651,4.061a6.141,6.141,0,0,0,5.819,0Z"
                                        transform="translate(-0.001 -7.79)"
                                    />{' '}
                                </g>{' '}
                            </svg>
                            <span className="icon-title">Layers</span>
                        </a>
                    </li>
                    <li className="nav-item icon-comments" role="presentation">
                        <a
                            className="nav-link"
                            id="icon-comments-tab"
                            data-toggle="pill"
                            href="#icon-comments"
                            role="tab"
                            aria-controls="icon-comments"
                            aria-selected="false"
                        >
                            <svg
                                className="bb-common-icon ic_Comments"
                                id="ic_Comments"
                                xmlns="http://www.w3.org/2000/svg"
                                width="36"
                                height="36"
                                viewBox="0 0 36 36"
                            >
                                {' '}
                                <rect
                                    id="Rectangle_1291"
                                    data-name="Rectangle 1291"
                                    width="36"
                                    height="36"
                                    opacity="0"
                                />{' '}
                                <path
                                    id="chat-heart"
                                    d="M.861,22.035A.861.861,0,0,1,0,21.174V11.121A11.044,11.044,0,0,1,11.017,0,10.987,10.987,0,0,1,22.034,11.125,11.028,11.028,0,0,1,10.977,22.034h-.011a10.884,10.884,0,0,1-4.921-1.156,3.813,3.813,0,0,0-4.407.736l-.168.168a.861.861,0,0,1-.609.252Zm3.5-3.277a5.477,5.477,0,0,1,2.458.582,9.18,9.18,0,0,0,4.151.974h.009a9.343,9.343,0,0,0,9.337-9.2A9.285,9.285,0,0,0,4.421,4.484a9.228,9.228,0,0,0-2.7,6.625s0,.006,0,.008v8.309a5.546,5.546,0,0,1,2.637-.669Zm6.659-3.138a2.727,2.727,0,0,1-1.636-.541,6.743,6.743,0,0,1-3.1-5.156c0-.006,0-.012,0-.017a2.8,2.8,0,0,1,4.734-2.023A2.8,2.8,0,0,1,15.751,9.9c0,.006,0,.012,0,.017a6.742,6.742,0,0,1-3.1,5.156,2.728,2.728,0,0,1-1.636.541ZM8,9.9a5.267,5.267,0,0,0,2.408,3.8,1.014,1.014,0,0,0,1.209,0A5.267,5.267,0,0,0,14.03,9.9a1.076,1.076,0,0,0-2.152,0,.861.861,0,0,1-1.721,0A1.076,1.076,0,0,0,8,9.9Z"
                                    transform="translate(7 7)"
                                    opacity="1"
                                />{' '}
                            </svg>
                            <span className="icon-title">Comments</span>
                        </a>
                    </li>
                    <li className="nav-item icon-more" role="presentation">
                        <a
                            className="nav-link"
                            id="icon-more-tab"
                            data-toggle="pill"
                            href="#icon-more"
                            role="tab"
                            aria-controls="icon-more"
                            aria-selected="false"
                        >
                            <svg
                                className="bb-common-icon ic_More"
                                id="ic_More"
                                xmlns="http://www.w3.org/2000/svg"
                                width="36"
                                height="36"
                                viewBox="0 0 36 36"
                            >
                                {' '}
                                <rect
                                    id="Rectangle_1293"
                                    data-name="Rectangle 1293"
                                    width="36"
                                    height="36"
                                    opacity="0"
                                />{' '}
                                <path
                                    id="Icon"
                                    d="M16,4a2,2,0,1,1,2-2A2,2,0,0,1,16,4ZM9,4a2,2,0,1,1,2-2A2,2,0,0,1,9,4ZM2,4A2,2,0,1,1,4,2,2,2,0,0,1,2,4Z"
                                    transform="translate(9 16)"
                                />{' '}
                            </svg>
                            <span className="icon-title">More</span>
                        </a>
                    </li>
                </ul>
            </li>
            <li className="bb-col-2">
                <div className="bb-left-panel">
                    <button className="btn left-panel-toggle">
                        <svg
                            width="20"
                            height="96"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            {' '}
                            <g>
                                {' '}
                                <path
                                    stroke=""
                                    d="m0,0.44941c0.00165,3.501 1.5132,9.396 7.55118,12.994l0.87224,0.518c6.87718,4.087 11.19379,6.652 11.39367,13.482l0.00661,0l0,40c0,7.207 -4.35957,9.798 -11.40194,13.982l-0.87059,0.518c-6.03797,3.598 -7.54953,9.493 -7.55118,12.994l0,0.506l0,-94.994l0.00001,0z"
                                    id="svg_1"
                                />{' '}
                            </g>{' '}
                        </svg>
                        <svg
                            className="left-panel-toggle-icon"
                            id="ic_LeftPanelClose"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                        >
                            {' '}
                            <rect
                                id="Rectangle_1773"
                                data-name="Rectangle 1773"
                                width="20"
                                height="20"
                                opacity="0"
                            />{' '}
                            <path
                                id="Icon"
                                d="M5.142,10.284a.57.57,0,0,1-.4-.167L.168,5.546a.571.571,0,0,1,0-.808L4.738.168a.571.571,0,0,1,.808.808L1.379,5.142,5.546,9.308a.571.571,0,0,1-.4.975"
                                transform="translate(7 5)"
                            />{' '}
                        </svg>
                    </button>

                    <div className="bb-col-2-inner">
                        <div
                            className="tab-content icon-area"
                            id="pills-tabContent"
                        >
                            <div
                                className="tab-pane icon-templates fade show active"
                                id="icon-templates"
                                role="tabpanel"
                                aria-labelledby="icon-templates-tab"
                            >
                                <div className="bb-tab-pane-inner custom-scrollbar">
                                    <form>
                                        <div className="bb-field-icon">
                                            <svg
                                                className="left-icon"
                                                id="ic_Search"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                            >
                                                {' '}
                                                <rect
                                                    id="bg"
                                                    width="24"
                                                    height="24"
                                                    fill="#fff"
                                                    opacity="0"
                                                />{' '}
                                                <g
                                                    id="search"
                                                    transform="translate(3.804 4)"
                                                >
                                                    {' '}
                                                    <path
                                                        id="Path_1356"
                                                        data-name="Path 1356"
                                                        d="M6.79,13.187a6.594,6.594,0,1,1,6.594-6.594A6.6,6.6,0,0,1,6.79,13.187Zm0-11.937a5.344,5.344,0,1,0,5.344,5.344A5.35,5.35,0,0,0,6.79,1.25Zm9.223,14.579a.625.625,0,0,0,0-.884l-2.8-2.8a.625.625,0,1,0-.884.884l2.8,2.8a.625.625,0,0,0,.884,0Z"
                                                        transform="translate(0)"
                                                    />{' '}
                                                </g>{' '}
                                            </svg>
                                            <svg
                                                className="right-icon"
                                                id="ic_SearchFilter"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                            >
                                                {' '}
                                                <rect
                                                    id="bg"
                                                    width="24"
                                                    height="24"
                                                    opacity="0"
                                                />{' '}
                                                <path
                                                    id="Icon"
                                                    d="M10,20a3.005,3.005,0,0,1-2.829-2H1a1,1,0,1,1,0-2H7.17a3,3,0,0,1,5.659,0H19a1,1,0,0,1,0,2H12.829A3,3,0,0,1,10,20Zm0-4a1,1,0,1,0,1,1A1,1,0,0,0,10,16Zm7-3a3,3,0,0,1-2.829-2H1A1,1,0,1,1,1,9H14.17A3,3,0,1,1,17,13Zm0-4a1,1,0,1,0,1,1A1,1,0,0,0,17,9ZM3,6A3,3,0,1,1,5.835,2.015.84.84,0,0,1,6,2H19a1,1,0,0,1,0,2H6a1.016,1.016,0,0,1-.167-.013A3,3,0,0,1,3,6ZM3,2A1,1,0,1,0,4,3,1,1,0,0,0,3,2Z"
                                                    transform="translate(2 2)"
                                                />{' '}
                                            </svg>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search Templates"
                                            />
                                        </div>
                                    </form>

                                    <div className="bb-card-carousel-wrapper left-bar">
                                        <ul className="d-flex justify-content-between align-content-end">
                                            <li className="bb-left-title">
                                                <span>Animals</span>
                                            </li>
                                            <li className="bb-right-title">
                                                <span>See all</span>
                                            </li>
                                        </ul>

                                        <div
                                            id="carouselExampleCaptions-1"
                                            className="carousel slide"
                                            data-ride="carousel"
                                        >
                                            <div className="carousel-inner">
                                                <div className="carousel-item active">
                                                    <div className="bb-carousel-wrap">
                                                        <img
                                                            src="https://unsplash.it/400/400/?animal"
                                                            className="bb-carousel-img"
                                                            alt="..."
                                                        />
                                                        <img
                                                            src="https://unsplash.it/400/400/?fish"
                                                            className="bb-carousel-img"
                                                            alt="..."
                                                        />
                                                    </div>
                                                </div>
                                                <div className="carousel-item">
                                                    <div className="bb-carousel-wrap">
                                                        <img
                                                            src="https://unsplash.it/400/400/?water"
                                                            className="bb-carousel-img"
                                                            alt="..."
                                                        />
                                                        <img
                                                            src="https://unsplash.it/400/400/?dog"
                                                            className="bb-carousel-img"
                                                            alt="..."
                                                        />
                                                    </div>
                                                </div>
                                                <div className="carousel-item">
                                                    <div className="bb-carousel-wrap">
                                                        <img
                                                            src="https://unsplash.it/400/400/?red"
                                                            className="bb-carousel-img"
                                                            alt="..."
                                                        />
                                                        <img
                                                            src="https://unsplash.it/400/400/?black"
                                                            className="bb-carousel-img"
                                                            alt="..."
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <a
                                                className="carousel-control-prev"
                                                href="#carouselExampleCaptions-1"
                                                role="button"
                                                data-slide="prev"
                                            >
                                                <span
                                                    className="carousel-control-prev-icon"
                                                    aria-hidden="true"
                                                ></span>
                                                <span className="sr-only">
                                                    Previous
                                                </span>
                                            </a>
                                            <a
                                                className="carousel-control-next"
                                                href="#carouselExampleCaptions-1"
                                                role="button"
                                                data-slide="next"
                                            >
                                                <span
                                                    className="carousel-control-next-icon"
                                                    aria-hidden="true"
                                                ></span>
                                                <span className="sr-only">
                                                    Next
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    {/*/.bb-card-carousel-wrapper */}

                                    <div className="bb-card-carousel-wrapper left-bar">
                                        <ul className="d-flex justify-content-between align-content-end">
                                            <li className="bb-left-title">
                                                <span>Animals</span>
                                            </li>
                                            <li className="bb-right-title">
                                                <span>See all</span>
                                            </li>
                                        </ul>

                                        <div
                                            id="carouselExampleCaptions-2"
                                            className="carousel slide"
                                            data-ride="carousel"
                                        >
                                            <div className="carousel-inner">
                                                <div className="carousel-item active">
                                                    <div className="bb-carousel-wrap">
                                                        <img
                                                            src="https://unsplash.it/400/400/?animal"
                                                            className="bb-carousel-img"
                                                            alt="..."
                                                        />
                                                        <img
                                                            src="https://unsplash.it/400/400/?fish"
                                                            className="bb-carousel-img"
                                                            alt="..."
                                                        />
                                                    </div>
                                                </div>
                                                <div className="carousel-item">
                                                    <div className="bb-carousel-wrap">
                                                        <img
                                                            src="https://unsplash.it/400/400/?water"
                                                            className="bb-carousel-img"
                                                            alt="..."
                                                        />
                                                        <img
                                                            src="https://unsplash.it/400/400/?dog"
                                                            className="bb-carousel-img"
                                                            alt="..."
                                                        />
                                                    </div>
                                                </div>
                                                <div className="carousel-item">
                                                    <div className="bb-carousel-wrap">
                                                        <img
                                                            src="https://unsplash.it/400/400/?red"
                                                            className="bb-carousel-img"
                                                            alt="..."
                                                        />
                                                        <img
                                                            src="https://unsplash.it/400/400/?black"
                                                            className="bb-carousel-img"
                                                            alt="..."
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <a
                                                className="carousel-control-prev"
                                                href="#carouselExampleCaptions-2"
                                                role="button"
                                                data-slide="prev"
                                            >
                                                <span
                                                    className="carousel-control-prev-icon"
                                                    aria-hidden="true"
                                                ></span>
                                                <span className="sr-only">
                                                    Previous
                                                </span>
                                            </a>
                                            <a
                                                className="carousel-control-next"
                                                href="#carouselExampleCaptions-2"
                                                role="button"
                                                data-slide="next"
                                            >
                                                <span
                                                    className="carousel-control-next-icon"
                                                    aria-hidden="true"
                                                ></span>
                                                <span className="sr-only">
                                                    Next
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    {/*/.bb-card-carousel-wrapper */}
                                </div>
                            </div>
                            {/*/.icon-templates */}

                            <div
                                className="tab-pane icon-text fade"
                                id="icon-text"
                                role="tabpanel"
                                aria-labelledby="icon-text-tab"
                            >
                                <div className="bb-tab-pane-inner custom-scrollbar">
                                    <Texts></Texts>
                                </div>
                            </div>

                            <div
                                className="tab-pane icon-shapes fade"
                                id="icon-shapes"
                                role="tabpanel"
                                aria-labelledby="icon-shapes-tab"
                            >
                                <div className="bb-tab-pane-inner custom-scrollbar">
                                    <Shapes/>
                                    
                                </div>
                            </div>
                            {/*/.icon-shapes */}

                            <div
                                className="tab-pane icon-clipart fade"
                                id="icon-clipart"
                                role="tabpanel"
                                aria-labelledby="icon-clipart-tab"
                            >
                                <div className="bb-tab-pane-inner custom-scrollbar">
                                   Coming soon 
                                </div>
                            </div>
                            {/*/.icon-clipart */}

                            <div
                                className="tab-pane icon-uploads fade"
                                id="icon-uploads"
                                role="tabpanel"
                                aria-labelledby="icon-uploads-tab"
                            >
                                <div className="bb-tab-pane-inner custom-scrollbar">
                                    <Uploads></Uploads>
                                </div>
                            </div>
                            {/*/.icon-uploads */}

                            <div
                                className="tab-pane icon-background fade"
                                id="icon-background"
                                role="tabpanel"
                                aria-labelledby="icon-background-tab"
                            >
                                <div className="bb-tab-pane-inner custom-scrollbar">
                                    .background..
                                </div>
                            </div>
                            {/*/.icon-background */}

                            <div
                                className="tab-pane icon-mydesigns fade"
                                id="icon-mydesigns"
                                role="tabpanel"
                                aria-labelledby="icon-mydesigns-tab"
                            >
                                <div className="bb-tab-pane-inner custom-scrollbar">
                                    -mydesigns..
                                </div>
                            </div>
                            {/*/.icon-mydesigns */}

                            <div
                                className="tab-pane icon-products fade"
                                id="icon-products"
                                role="tabpanel"
                                aria-labelledby="icon-products-tab"
                            >
                                <div className="bb-tab-pane-inner custom-scrollbar">
                                    <Products></Products>
                                </div>
                            </div>
                            {/*/.icon-products */}

                            <div
                                className="tab-pane icon-layers fade"
                                id="icon-layers"
                                role="tabpanel"
                                aria-labelledby="icon-layers-tab"
                            >
                                <div className="bb-tab-pane-inner custom-scrollbar">
                                    <Layers></Layers>
                                </div>
                            </div>
                            {/*/.icon-layers */}

                            <div
                                className="tab-pane icon-comments fade"
                                id="icon-comments"
                                role="tabpanel"
                                aria-labelledby="icon-comments-tab"
                            >
                                <div className="bb-tab-pane-inner custom-scrollbar">
                                    .comments..
                                </div>
                            </div>
                            {/*/.icon-comments */}

                            <div
                                className="tab-pane icon-more fade"
                                id="icon-more"
                                role="tabpanel"
                                aria-labelledby="icon-more-tab"
                            >
                                <div className="bb-tab-pane-inner custom-scrollbar">
                                   Coming soon 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </Fragment>
    )
}

export default SidebarLeft
