import Header from '../components/Header/index';
import React, { Fragment } from 'react';
const DesignToolLayout = (props) => {
  return (
    <Fragment>
      <div>
        <Header />
            
        {props.children}
        {/* <Footer /> */}
      </div>
    </Fragment>
  );
};

export default DesignToolLayout;
