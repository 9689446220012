import { userConstants } from "../../constants";
import { userService } from "../../services";
import { alertActions } from ".";
import { history } from "../../helpers";

const login = (username, password, from) => {
  return async (dispatch) => {
    dispatch({ type: userConstants.LOGIN_REQUEST, username });
    try {
      const user = await userService.login(username, password);
      dispatch({ type: userConstants.LOGIN_SUCCESS, user });
      history.push(from);
    } catch (err) {
      // Handle Error
      let error = err.toString();
      dispatch({ type: userConstants.LOGIN_FAILURE, error });
      dispatch(alertActions.error(err.toString()));
    }
  };
};
const logout = () => {
  userService.logout();
  return { type: userConstants.LOGOUT };
};
export const userActions = {
  login,
  logout,
};
