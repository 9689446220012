import React, { Fragment } from 'react'
import cvVinBanner from '../../assets/images/banner-buzz/custom-vinyl-banners.jpg'
import { Helmet } from 'react-helmet'

const SidebarLeft = (props) => {
    return (
        <Fragment>
            <li className="bb-col-4">
                <div className="bb-right-panel">
                    <button className="btn right-panel-toggle">
                        <svg
                            width="20"
                            height="96"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            {' '}
                            <g>
                                {' '}
                                <title>Layer 1</title>{' '}
                                <path
                                    transform="rotate(-180 9.911845207214355,47.9464111328125) "
                                    stroke=""
                                    d="m0,0.44941c0.00165,3.501 1.5132,9.396 7.55118,12.994l0.87224,0.518c6.87718,4.087 11.19379,6.652 11.39367,13.482l0.00661,0l0,40c0,7.207 -4.35957,9.798 -11.40194,13.982l-0.87059,0.518c-6.03797,3.598 -7.54953,9.493 -7.55118,12.994l0,0.506l0,-94.994l0.00001,0z"
                                    id="svg_1"
                                />{' '}
                            </g>{' '}
                        </svg>
                        <svg
                            className="right-panel-toggle-icon"
                            id="ic_LeftPanelOpen"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                        >
                            {' '}
                            <rect
                                id="Rectangle_1773"
                                data-name="Rectangle 1773"
                                width="20"
                                height="20"
                                opacity="0"
                            />{' '}
                            <path
                                id="Icon"
                                d="M.571,10.284a.57.57,0,0,0,.4-.167l4.57-4.57a.571.571,0,0,0,0-.808L.975.168A.571.571,0,1,0,.168.975L4.334,5.142.168,9.308a.571.571,0,0,0,.4.975"
                                transform="translate(7 5)"
                            />{' '}
                        </svg>
                    </button>
                    <div className="bb-col-4-inner custom-scrollbar">
                        <div className="bb-col-4-inner-a">
                            <img
                                src={cvVinBanner}
                                alt="cV"
                                className="img-fluid"
                            />
                            <h6 className="fw-semi-bold text-center">
                                Custom Vinyl Banners
                            </h6>

                            <div className="row no-gutters align-items-center mb-3">
                                <div className="col-3">Size</div>
                                <div className="col-6 pr-0">
                                    <select
                                        className="form-control"
                                        id="exampleFormControlSelect1"
                                    >
                                        <option>3.5 x 5</option>
                                        <option>5 x 5</option>
                                        <option>5 x 7</option>
                                    </select>
                                </div>
                                <div className="col-3 pl-0 text-right">
                                    <button
                                        className="btn bg-9 color-2 fw-bold"
                                        style={{ width: '60px' }}
                                    >
                                        {' '}
                                        ft{' '}
                                    </button>
                                </div>
                            </div>
                            {/*/.row */}

                            <div className="row no-gutters align-items-center mb-3">
                                <div className="col-3">Slides</div>
                                <div className="col-9">
                                    <select
                                        className="form-control"
                                        id="exampleFormControlSelect1"
                                    >
                                        <option>One Slide</option>
                                        <option>Two Slide</option>
                                        <option>Three Slide</option>
                                    </select>
                                </div>
                            </div>
                            {/*/.row */}

                            <div className="row no-gutters align-items-center mb-3">
                                <div className="col-3">Price</div>
                                <div className="col-4 pr-0">
                                    <p className="mb-0">$199.12</p>
                                </div>
                                <div className="col-5 pl-0 text-right">
                                    <a
                                        href="#"
                                        className="btn bg-9 color-3 fw-bold"
                                    >
                                        Update Cart
                                    </a>
                                </div>
                            </div>
                            {/*/.row */}
                        </div>

                        <div className="bb-col-4-inner-b">
                            <div className="bb-card-carousel-wrapper right-bar">
                                <div
                                    id="carouselExampleCaptions-3"
                                    className="carousel slide"
                                    data-ride="carousel"
                                >
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <div className="bb-carousel-wrap">
                                                <img
                                                    src="https://unsplash.it/200/100/?animal"
                                                    className="bb-carousel-img"
                                                    alt="..."
                                                />
                                                <div className="carousel-caption">
                                                    <p>First slide label</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="bb-carousel-wrap">
                                                <img
                                                    src="https://unsplash.it/200/100/?water"
                                                    className="bb-carousel-img"
                                                    alt="..."
                                                />
                                                <div className="carousel-caption">
                                                    <p>Second slide label</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="bb-carousel-wrap">
                                                <img
                                                    src="https://unsplash.it/200/100/?red"
                                                    className="bb-carousel-img"
                                                    alt="..."
                                                />
                                                <div className="carousel-caption">
                                                    <p>Third slide label</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <a
                                        className="carousel-control-prev"
                                        href="#carouselExampleCaptions-3"
                                        role="button"
                                        data-slide="prev"
                                    >
                                        <span
                                            className="carousel-control-prev-icon"
                                            aria-hidden="true"
                                        ></span>
                                        <span className="sr-only">
                                            Previous
                                        </span>
                                    </a>
                                    <a
                                        className="carousel-control-next"
                                        href="#carouselExampleCaptions-3"
                                        role="button"
                                        data-slide="next"
                                    >
                                        <span
                                            className="carousel-control-next-icon"
                                            aria-hidden="true"
                                        ></span>
                                        <span className="sr-only">Next</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Helmet>
                    <script src="/js/js-plugins.js" type="text/javascript" />
                </Helmet>
            </li>
        </Fragment>
    )
}

export default SidebarLeft
