import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./Login.scss";
import { userActions } from "./../../store/actions";

function Login() {
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const { email, password } = inputs;
  const loggingIn = useSelector((state) => state.authentication.loggingIn);
  const dispatch = useDispatch();
  const location = useLocation();

  // reset login status
  useEffect(() => {
    dispatch(userActions.logout());
  }, [dispatch]);

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    setSubmitted(true);
    if (email && password) {
      // get return url from location state or default to home page
      const { from } = location.state || { from: { pathname: "/" } };
      dispatch(userActions.login(email, password, from));
    }
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 min-vh-100 d-flex flex-column justify-content-center">
          <div className="row">
            <div className="col-lg-6 col-md-8 mx-auto">
              <div className="card rounded shadow shadow-sm">
                <div className="card-header">
                  <h3 className="mb-0">Login</h3>
                </div>
                <div className="card-body">
                  <form name="form" onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label>email</label>
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                        className={
                          "form-control" +
                          (submitted && !email ? " is-invalid" : "")
                        }
                      />
                      {submitted && !email && (
                        <div className="invalid-feedback">
                          email is required
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <input
                        type="password"
                        name="password"
                        value={password}
                        onChange={handleChange}
                        className={
                          "form-control" +
                          (submitted && !password ? " is-invalid" : "")
                        }
                      />
                      {submitted && !password && (
                        <div className="invalid-feedback">
                          Password is required
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <button className="btn btn-primary">
                        {loggingIn && (
                          <span className="spinner-border spinner-border-sm mr-1"></span>
                        )}
                        Login
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Login;
