import React, {Fragment} from 'react';
import { connect } from 'react-redux'
import { exportToSvg,generatePriveiew, ungroup, toGroup} from '../../actions/index'
import { bindActionCreators } from 'redux'

import logoImage from '../../assets/images/banner-buzz/logo.svg'
const Header = (props) => {
    return (
      <Fragment>
          <nav className="navbar navbar-expand-md navbar-dark" >
		<a className="navbar-brand py-0" href="/#">
			<img src={logoImage} alt=""  className="img-fluid" />
		</a>
		<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample04"
			aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
			<span className="navbar-toggler-icon"></span>
		</button>

		<div className="collapse navbar-collapse" id="navbarsExample04">
			<ul className="navbar-nav nav-left-side mr-auto">
				<li className="nav-item">
					<a className="nav-link" href="/#" id="ic_Save" data-toggle="tooltip" title="Save" onClick={()=>props.generatePriveiew(props.canvas)}>
						<svg id="ic_Save" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"> <rect id="Rectangle_1242" data-name="Rectangle 1242" width="36" height="36" opacity="0"/> <rect id="Rectangle_1240" data-name="Rectangle 1240" width="1.179" height="1.254" transform="translate(17 17)"/> <path id="Path_94" data-name="Path 94" d="M15.42,0H4.771A3.838,3.838,0,0,0,.929,3.824V14.509a3.838,3.838,0,0,0,3.842,3.824H15.42a3.838,3.838,0,0,0,3.842-3.824V3.824A3.838,3.838,0,0,0,15.42,0ZM13.388,1.4a1.126,1.126,0,0,1,1.221.993V8.14a1.126,1.126,0,0,1-1.221.993H6.8A1.126,1.126,0,0,1,5.582,8.14V2.394A1.126,1.126,0,0,1,6.8,1.4ZM7.508,16.932a.532.532,0,0,1-.533-.53V15a.532.532,0,0,1,.533-.53H12.83a.532.532,0,0,1,.533.53v1.4a.532.532,0,0,1-.533.53Zm10.347-2.423a2.432,2.432,0,0,1-2.435,2.423H14.7a1.916,1.916,0,0,0,.075-.53V15a1.938,1.938,0,0,0-1.94-1.931H7.508A1.938,1.938,0,0,0,5.567,15v1.4a1.916,1.916,0,0,0,.075.53H4.771a2.432,2.432,0,0,1-2.435-2.423V3.824A2.431,2.431,0,0,1,4.4,1.429a2.2,2.2,0,0,0-.224.965V8.14A2.523,2.523,0,0,0,6.8,10.534h6.585A2.523,2.523,0,0,0,16.017,8.14V2.394a2.2,2.2,0,0,0-.224-.965,2.431,2.431,0,0,1,2.061,2.395Z" transform="translate(8.071 8)"/> </svg>
					</a>
				</li>
				<li className="nav-item">
					<a className="nav-link" href="/#" id="ic_group" data-toggle="tooltip" title="UnGroup"   onClick={()=>{
						let activeObject = props.canvas.selectedCanvas.canvas.getActiveObject()
						if(activeObject.type =='group')
						props.ungroup(activeObject)
					 } } >
						<svg id="ic_Paste" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"> <rect id="Rectangle_1256" data-name="Rectangle 1256" width="36" height="36" opacity="0"/> <g id="Group_132" data-name="Group 132" transform="translate(10 8)"> <path id="paste" d="M5.121,17.136h-1.1A4.021,4.021,0,0,1,0,13.12v-9.1A4.021,4.021,0,0,1,4.016,0h6.158a4.021,4.021,0,0,1,4.016,4.016V4.05a.669.669,0,1,1-1.339,0V4.016a2.681,2.681,0,0,0-2.678-2.678H4.016A2.681,2.681,0,0,0,1.339,4.016v9.1A2.681,2.681,0,0,0,4.016,15.8h1.1a.669.669,0,0,1,0,1.339ZM10.71,3.28a.669.669,0,0,0-1.339,0,1,1,0,0,1-1,1H5.824a1,1,0,0,1-1-1,.669.669,0,0,0-1.339,0A2.345,2.345,0,0,0,5.824,5.623H8.367A2.345,2.345,0,0,0,10.71,3.28Z" transform="translate(0 0)"/> <path id="circle-plus" d="M64.015,1.629H62.978V.592a.592.592,0,0,0-1.185,0V1.629H60.757a.592.592,0,1,0,0,1.185h1.037V3.85a.592.592,0,0,0,1.185,0V2.813h1.037a.592.592,0,0,0,0-1.185Z" transform="translate(-50.822 10.233)"/> <g id="copy" transform="translate(6.377 6.377)"> <path id="Path_103" data-name="Path 103" d="M28.154,15.793h-2.13a4.013,4.013,0,0,1-4.008-4.008V7.845a4.013,4.013,0,0,1,4.008-4.008h2.13a4.013,4.013,0,0,1,4.008,4.008v3.94A4.013,4.013,0,0,1,28.154,15.793Zm-2.13-10.62a2.675,2.675,0,0,0-2.672,2.672v3.94a2.675,2.675,0,0,0,2.672,2.672h2.13a2.675,2.675,0,0,0,2.672-2.672V7.845a2.675,2.675,0,0,0-2.672-2.672Z" transform="translate(-22.016 -3.836)"/> </g> </g> </svg>
					</a>
				</li>
				<li className="nav-item">
					<a className="nav-link" href="/#" id="ic_group" data-toggle="tooltip" title="Group"   onClick={()=>{
						let activeObject = props.canvas.selectedCanvas.canvas.getActiveObject()
						console.log("activeObject",activeObject)
						if(activeObject._objects &&  activeObject._objects.length >0)
						props.toGroup(activeObject)
					
					}}>
						<svg id="ic_Paste" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"> <rect id="Rectangle_1256" data-name="Rectangle 1256" width="36" height="36" opacity="0"/> <g id="Group_132" data-name="Group 132" transform="translate(10 8)"> <path id="paste" d="M5.121,17.136h-1.1A4.021,4.021,0,0,1,0,13.12v-9.1A4.021,4.021,0,0,1,4.016,0h6.158a4.021,4.021,0,0,1,4.016,4.016V4.05a.669.669,0,1,1-1.339,0V4.016a2.681,2.681,0,0,0-2.678-2.678H4.016A2.681,2.681,0,0,0,1.339,4.016v9.1A2.681,2.681,0,0,0,4.016,15.8h1.1a.669.669,0,0,1,0,1.339ZM10.71,3.28a.669.669,0,0,0-1.339,0,1,1,0,0,1-1,1H5.824a1,1,0,0,1-1-1,.669.669,0,0,0-1.339,0A2.345,2.345,0,0,0,5.824,5.623H8.367A2.345,2.345,0,0,0,10.71,3.28Z" transform="translate(0 0)"/> <path id="circle-plus" d="M64.015,1.629H62.978V.592a.592.592,0,0,0-1.185,0V1.629H60.757a.592.592,0,1,0,0,1.185h1.037V3.85a.592.592,0,0,0,1.185,0V2.813h1.037a.592.592,0,0,0,0-1.185Z" transform="translate(-50.822 10.233)"/> <g id="copy" transform="translate(6.377 6.377)"> <path id="Path_103" data-name="Path 103" d="M28.154,15.793h-2.13a4.013,4.013,0,0,1-4.008-4.008V7.845a4.013,4.013,0,0,1,4.008-4.008h2.13a4.013,4.013,0,0,1,4.008,4.008v3.94A4.013,4.013,0,0,1,28.154,15.793Zm-2.13-10.62a2.675,2.675,0,0,0-2.672,2.672v3.94a2.675,2.675,0,0,0,2.672,2.672h2.13a2.675,2.675,0,0,0,2.672-2.672V7.845a2.675,2.675,0,0,0-2.672-2.672Z" transform="translate(-22.016 -3.836)"/> </g> </g> </svg>
					</a>
				</li>
				<li className="nav-item">
					<a className="nav-link" href="/#" id="ic_Undo" data-toggle="tooltip" title="Undo"  >
						<svg id="ic_Undo" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"> <rect id="Rectangle_1252" data-name="Rectangle 1252" width="36" height="36" opacity="0"/> <g id="Group_130" data-name="Group 130" transform="translate(9.028 10)"> <path id="Path_108" data-name="Path 108" d="M2622.307,859.3a6.192,6.192,0,0,0,.979-3.289s.325-6.675-6.746-6.638-11.291,0-11.291,0" transform="translate(-2604.956 -846.633)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.3"/> <path id="Path_109" data-name="Path 109" d="M2600.56,856.625l-2.82,2.82,2.82,2.819" transform="translate(-2597.74 -856.625)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.3"/> <path id="Path_110" data-name="Path 110" d="M2610.178,861.2h5.664c6.818,0,6.746-6.024,6.746-6.024" transform="translate(-2604.257 -845.798)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.3" strokeDasharray="1.5 3"/> </g> </svg>
					</a>
				</li>
				<li className="nav-item">
					<a className="nav-link" href="/#" id="ic_Reundo" data-toggle="tooltip" title="Reundo">
						<svg id="ic_Reundo" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"> <g id="reundo" transform="translate(-0.152 -0.5)" opacity="1"> <rect id="Rectangle_1253" data-name="Rectangle 1253" width="36" height="36" transform="translate(0.152 0.5)" opacity="0"/> <g id="Group_131" data-name="Group 131" transform="translate(8.569 10.5)"> <path id="Path_108" data-name="Path 108" d="M2606.232,849.375a6.192,6.192,0,0,0-.979,3.289s-.325,6.675,6.746,6.638,11.291,0,11.291,0" transform="translate(-2605.25 -846.639)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.3"/> <path id="Path_109" data-name="Path 109" d="M2597.74,862.264l2.82-2.82-2.82-2.819" transform="translate(-2582.226 -846.861)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.3"/> <path id="Path_110" data-name="Path 110" d="M2622.514,855.177h-5.664c-6.818,0-6.746,6.024-6.746,6.024" transform="translate(-2610.102 -855.177)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.3" strokeasharray="1.5 3"/> </g> </g> </svg>
					</a>
				</li>
				<li className="nav-item">
					<a className="nav-link" href="/#" id="ic_Cut" data-toggle="tooltip" title="Cut">
						<svg id="ic_Cut" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"> <rect id="Rectangle_1254" data-name="Rectangle 1254" width="36" height="36" opacity="0"/> <path id="Path_104" data-name="Path 104" d="M17.588,16.776a.712.712,0,0,1-.461-.169L4.938,6.315a3.2,3.2,0,1,1,.458-5,.715.715,0,0,1,.066.059,3.167,3.167,0,0,1,.708,1.09.715.715,0,0,1-1.331.523,1.76,1.76,0,0,0-.345-.557q-.026-.021-.05-.045a1.767,1.767,0,1,0,0,2.5.715.715,0,0,1,.967-.041L18.049,15.515a.715.715,0,0,1-.462,1.261ZM5.4,17.865a.713.713,0,0,0,.066-.059,3.167,3.167,0,0,0,.708-1.09.715.715,0,0,0-1.331-.523,1.76,1.76,0,0,1-.345.557q-.026.021-.05.045a1.755,1.755,0,0,1-1.249.517h0A1.767,1.767,0,0,1,1.946,14.3l0,0a1.735,1.735,0,0,1,1.236-.486,1.744,1.744,0,0,1,1.26.506.715.715,0,0,0,.969.039L8.148,12.03a.715.715,0,0,0-.926-1.09L4.935,12.883a3.2,3.2,0,0,0-1.739-.5h-.02a3.163,3.163,0,0,0-2.241.906l0,0a3.2,3.2,0,0,0,2.262,5.455h0a3.174,3.174,0,0,0,2.2-.878Zm7.075-9.515,5.577-4.683a.715.715,0,1,0-.92-1.1L11.551,7.255a.715.715,0,1,0,.92,1.1Zm4.969,2.071a.894.894,0,1,1,.894-.894A.894.894,0,0,1,17.439,10.421ZM15.58,9.527a.894.894,0,1,0-.894.894A.894.894,0,0,0,15.58,9.527Zm-11.011,0a.894.894,0,1,0-.894.894A.894.894,0,0,0,4.569,9.527Zm-2.753,0a.894.894,0,1,0-.894.894A.894.894,0,0,0,1.816,9.527Z" transform="translate(9 7.56)"/> </svg>
					</a>
				</li>
				<li className="nav-item">
					<a className="nav-link" href="/#" id="ic_Copy" data-toggle="tooltip" title="Copy" >
						<svg id="ic_Copy" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"> <rect id="Rectangle_1255" data-name="Rectangle 1255" width="36" height="36" opacity="0"/> <path id="Path_103" data-name="Path 103" d="M14.53,18.333H7.669A4.293,4.293,0,0,1,3.38,14.045V8.684A4.293,4.293,0,0,1,7.669,4.4H14.53a4.293,4.293,0,0,1,4.288,4.288v5.361a4.293,4.293,0,0,1-4.288,4.289ZM7.669,5.825A2.862,2.862,0,0,0,4.81,8.684v5.361A2.862,2.862,0,0,0,7.669,16.9H14.53a2.862,2.862,0,0,0,2.859-2.859V8.684A2.862,2.862,0,0,0,14.53,5.825Zm5.4,3.86a.893.893,0,1,0-.893-.893A.893.893,0,0,0,13.068,9.685ZM1.951,11.47V4.289A2.862,2.862,0,0,1,4.81,1.43h6.862a2.863,2.863,0,0,1,2.35,1.23.715.715,0,1,0,1.174-.816A4.293,4.293,0,0,0,11.671,0H4.81A4.293,4.293,0,0,0,.521,4.289V11.47a.715.715,0,1,0,1.429,0Zm5.524,2.5L9.233,12.12a1.069,1.069,0,0,1,1.36-.031l1.087.889.009.007a2.508,2.508,0,0,0,1.627.6,2.35,2.35,0,0,0,1.673-.845l.016-.018L16.042,11.5a.715.715,0,0,0-1.088-.928L13.926,11.78a.858.858,0,0,1-1.346.087l-1.087-.889-.009-.007a2.5,2.5,0,0,0-3.225.1q-.02.018-.04.038L6.439,12.98a.715.715,0,1,0,1.035.986Z" transform="translate(8.479 8)"/> </svg>
					</a>
				</li>
				<li className="nav-item">
					<a className="nav-link" href="/#" id="ic_Paste" data-toggle="tooltip" title="Paste">
						<svg id="ic_Paste" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"> <rect id="Rectangle_1256" data-name="Rectangle 1256" width="36" height="36" opacity="0"/> <g id="Group_132" data-name="Group 132" transform="translate(10 8)"> <path id="paste" d="M5.121,17.136h-1.1A4.021,4.021,0,0,1,0,13.12v-9.1A4.021,4.021,0,0,1,4.016,0h6.158a4.021,4.021,0,0,1,4.016,4.016V4.05a.669.669,0,1,1-1.339,0V4.016a2.681,2.681,0,0,0-2.678-2.678H4.016A2.681,2.681,0,0,0,1.339,4.016v9.1A2.681,2.681,0,0,0,4.016,15.8h1.1a.669.669,0,0,1,0,1.339ZM10.71,3.28a.669.669,0,0,0-1.339,0,1,1,0,0,1-1,1H5.824a1,1,0,0,1-1-1,.669.669,0,0,0-1.339,0A2.345,2.345,0,0,0,5.824,5.623H8.367A2.345,2.345,0,0,0,10.71,3.28Z" transform="translate(0 0)"/> <path id="circle-plus" d="M64.015,1.629H62.978V.592a.592.592,0,0,0-1.185,0V1.629H60.757a.592.592,0,1,0,0,1.185h1.037V3.85a.592.592,0,0,0,1.185,0V2.813h1.037a.592.592,0,0,0,0-1.185Z" transform="translate(-50.822 10.233)"/> <g id="copy" transform="translate(6.377 6.377)"> <path id="Path_103" data-name="Path 103" d="M28.154,15.793h-2.13a4.013,4.013,0,0,1-4.008-4.008V7.845a4.013,4.013,0,0,1,4.008-4.008h2.13a4.013,4.013,0,0,1,4.008,4.008v3.94A4.013,4.013,0,0,1,28.154,15.793Zm-2.13-10.62a2.675,2.675,0,0,0-2.672,2.672v3.94a2.675,2.675,0,0,0,2.672,2.672h2.13a2.675,2.675,0,0,0,2.672-2.672V7.845a2.675,2.675,0,0,0-2.672-2.672Z" transform="translate(-22.016 -3.836)"/> </g> </g> </svg>
					</a>
				</li>
				<li className="nav-item bb-align-dropdown-a">
					<div className="dropdown">
						<a className="nav-link dropdown-toggle" href="/#" role="button" id="dropdownMenuLink"
							data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<svg id="ic_Align" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"> <rect id="Rectangle_1257" data-name="Rectangle 1257" width="36" height="36" opacity="0"/> <g id="Group_133" data-name="Group 133" transform="translate(9 8)"> <path id="price-high" d="M2.65,0A2.653,2.653,0,0,0,0,2.65V15.684a2.65,2.65,0,1,0,5.3,0V2.65A2.653,2.653,0,0,0,2.65,0ZM3.867,15.684a1.217,1.217,0,1,1-2.435,0V2.65a1.217,1.217,0,1,1,2.435,0Z" transform="translate(18.333 2.971) rotate(90)"/> <path id="price-high-2" data-name="price-high" d="M2.65,0A2.653,2.653,0,0,0,0,2.65v8.057a2.65,2.65,0,1,0,5.3,0V2.65A2.653,2.653,0,0,0,2.65,0ZM3.867,10.706a1.217,1.217,0,1,1-2.435,0V2.65a1.217,1.217,0,1,1,2.435,0Z" transform="translate(16.065 9.753) rotate(90)"/> <path id="Path_118" data-name="Path 118" d="M2679.957,990.25v3.4" transform="translate(-2670.79 -990.25)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.3"/> <path id="Path_120" data-name="Path 120" d="M2679.957,990.25v2.02" transform="translate(-2670.79 -982.029)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.3"/> <path id="Path_121" data-name="Path 121" d="M2679.957,990.25v3.4" transform="translate(-2670.79 -975.508)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.3"/> </g> </svg>
						</a>

						<div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
							<div className="bb-align-dropdown-a1">
								<span>Horizontal Align</span>
								<ul className="list-inline text-center d-flex justify-content-between bb-align-dropdown-a1_ul">
									<li>
										<button className="btn">
											<svg id="ic_Align-Top" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"> <g id="Align_HC" transform="translate(24) rotate(90)" opacity="0.9"> <rect id="Rectangle_1257" data-name="Rectangle 1257" width="24" height="24" fill="#dfdfdf" opacity="0"/> <g id="Group_119" data-name="Group 119" transform="translate(2.043 1.035)"> <path id="price-high" d="M1.893,11.344A1.782,1.782,0,0,1,0,9.7V1.64A1.782,1.782,0,0,1,1.893,0,1.782,1.782,0,0,1,3.787,1.64V9.7A1.782,1.782,0,0,1,1.893,11.344Zm.87-9.7a.819.819,0,0,0-.87-.753.819.819,0,0,0-.87.753V9.7a.819.819,0,0,0,.87.753.819.819,0,0,0,.87-.753Z" transform="translate(5.612 14.842) rotate(-90)"/> <path id="price-high-2" data-name="price-high" d="M1.893,8.1A1.77,1.77,0,0,1,0,6.5V1.608A1.77,1.77,0,0,1,1.893,0,1.77,1.77,0,0,1,3.787,1.608V6.5A1.77,1.77,0,0,1,1.893,8.1Zm.87-6.5a.813.813,0,0,0-.87-.739.813.813,0,0,0-.87.739V6.5a.813.813,0,0,0,.87.739.813.813,0,0,0,.87-.739Z" transform="translate(5.612 9.996) rotate(-90)"/> <path id="Path_118" data-name="Path 118" d="M0,12.965V0" transform="translate(3.957 4)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1.3"/> </g> </g> </svg>
											<p>Top</p>
										</button>
									</li>
									<li>
										<button className="btn">
											<svg id="ic_Align-Middle" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"> <g id="Align_HC" transform="translate(24) rotate(90)" opacity="0.9"> <rect id="Rectangle_1257" data-name="Rectangle 1257" width="24" height="24" fill="#dfdfdf" opacity="0"/> <g id="Group_120" data-name="Group 120" transform="translate(2.127 1.134)"> <path id="price-high" d="M1.879,13A1.881,1.881,0,0,1,0,11.121V1.879a1.879,1.879,0,0,1,3.758,0v9.242A1.881,1.881,0,0,1,1.879,13ZM2.742,1.879a.863.863,0,1,0-1.727,0v9.242a.863.863,0,1,0,1.727,0Z" transform="translate(3.873 14.759) rotate(-90)"/> <path id="price-high-2" data-name="price-high" d="M1.879,9.471A1.881,1.881,0,0,1,0,7.592V1.879a1.879,1.879,0,0,1,3.758,0V7.592A1.881,1.881,0,0,1,1.879,9.471Zm.863-7.592a.863.863,0,1,0-1.727,0V7.592a.863.863,0,1,0,1.727,0Z" transform="translate(5.794 9.95) rotate(-90)"/> <path id="Path_118" data-name="Path 118" d="M0,2.412V0" transform="translate(10.373 14.454)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1.3"/> <path id="Path_120" data-name="Path 120" d="M0,1.432V0" transform="translate(10.373 9.604)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1.3"/> <path id="Path_121" data-name="Path 121" d="M0,2.412V0" transform="translate(10.373 4)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1.3"/> </g> </g> </svg>
											<p>Middle</p>
										</button>
									</li>
									<li>
										<button className="btn">
											<svg id="ic_Align-Bottom" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"> <g id="Align_HC" transform="translate(24) rotate(90)" opacity="0.9"> <rect id="Rectangle_1257" data-name="Rectangle 1257" width="24" height="24" fill="#dfdfdf" opacity="0"/> <g id="Group_121" data-name="Group 121" transform="translate(2 1.035)"> <path id="price-high" d="M1.893,0A1.782,1.782,0,0,0,0,1.64V9.7a1.782,1.782,0,0,0,1.893,1.64A1.782,1.782,0,0,0,3.787,9.7V1.64A1.782,1.782,0,0,0,1.893,0Zm.87,9.7a.819.819,0,0,1-.87.753.819.819,0,0,1-.87-.753V1.64a.819.819,0,0,1,.87-.753.819.819,0,0,1,.87.753Z" transform="translate(4 14.842) rotate(-90)"/> <path id="price-high-2" data-name="price-high" d="M1.893,0A1.77,1.77,0,0,0,0,1.608V6.5A1.77,1.77,0,0,0,1.893,8.1,1.77,1.77,0,0,0,3.787,6.5V1.608A1.77,1.77,0,0,0,1.893,0Zm.87,6.5a.813.813,0,0,1-.87.739.813.813,0,0,1-.87-.739V1.608a.813.813,0,0,1,.87-.739.813.813,0,0,1,.87.739Z" transform="translate(7.241 9.996) rotate(-90)"/> <path id="Path_118" data-name="Path 118" d="M0,12.965V0" transform="translate(17 4)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1.3"/> </g> </g> </svg>
											<p>Bottom</p>
										</button>
									</li>
								</ul>
								<span>Vertical Align</span>
								<ul className="list-inline text-center d-flex justify-content-between bb-align-dropdown-a1_ul">
									<li>
										<button className="btn">
											<svg id="ic_Align-Left" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"> <g id="Align_HC" transform="translate(-133 -330)" opacity="0.9"> <rect id="Rectangle_1257" data-name="Rectangle 1257" width="24" height="24" transform="translate(133 330)" fill="#dfdfdf" opacity="0"/> <g id="Group_122" data-name="Group 122" transform="translate(2.043 2)"> <path id="price-high" d="M1.893,0A1.782,1.782,0,0,0,0,1.64V9.7a1.782,1.782,0,0,0,1.893,1.64A1.782,1.782,0,0,0,3.787,9.7V1.64A1.782,1.782,0,0,0,1.893,0Zm.87,9.7a.819.819,0,0,1-.87.753.819.819,0,0,1-.87-.753V1.64a.819.819,0,0,1,.87-.753.819.819,0,0,1,.87.753Z" transform="translate(149.957 336.123) rotate(90)"/> <path id="price-high-2" data-name="price-high" d="M1.893,0A1.77,1.77,0,0,0,0,1.608V6.5A1.77,1.77,0,0,0,1.893,8.1,1.77,1.77,0,0,0,3.787,6.5V1.608A1.77,1.77,0,0,0,1.893,0Zm.87,6.5a.813.813,0,0,1-.87.739.813.813,0,0,1-.87-.739V1.608a.813.813,0,0,1,.87-.739.813.813,0,0,1,.87.739Z" transform="translate(146.715 340.969) rotate(90)"/> <path id="Path_118" data-name="Path 118" d="M2679.957,990.25v12.965" transform="translate(-2543 -656.25)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1.3"/> </g> </g> </svg>
											<p className="mb-0">Left</p>
										</button>
									</li>
									<li>
										<button className="btn">
											<svg id="ic_Align-Center" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"> <g id="Align_HC" transform="translate(-133 -330)" opacity="0.9"> <rect id="Rectangle_1257" data-name="Rectangle 1257" width="24" height="24" transform="translate(133 330)" fill="#dfdfdf" opacity="0"/> <g id="Group_123" data-name="Group 123" transform="translate(2.127 2)"> <path id="price-high" d="M1.879,0A1.881,1.881,0,0,0,0,1.879v9.242a1.879,1.879,0,0,0,3.758,0V1.879A1.881,1.881,0,0,0,1.879,0Zm.863,11.121a.863.863,0,1,1-1.727,0V1.879a.863.863,0,1,1,1.727,0Z" transform="translate(149.873 336.107) rotate(90)"/> <path id="price-high-2" data-name="price-high" d="M1.879,0A1.881,1.881,0,0,0,0,1.879V7.592a1.879,1.879,0,0,0,3.758,0V1.879A1.881,1.881,0,0,0,1.879,0Zm.863,7.592a.863.863,0,0,1-1.727,0V1.879a.863.863,0,1,1,1.727,0Z" transform="translate(148.265 340.915) rotate(90)"/> <path id="Path_118" data-name="Path 118" d="M2679.957,990.25v2.412" transform="translate(-2536.583 -656.25)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1.3"/> <path id="Path_120" data-name="Path 120" d="M2679.957,990.25v1.432" transform="translate(-2536.583 -650.42)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1.3"/> <path id="Path_121" data-name="Path 121" d="M2679.957,990.25v2.412" transform="translate(-2536.583 -645.797)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1.3"/> </g> </g> </svg>
											<p className="mb-0">Center</p>
										</button>
									</li>
									<li>
										<button className="btn">
											<svg id="ic_Align-Right" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"> <g id="Align_HC" opacity="0.9"> <rect id="Rectangle_1257" data-name="Rectangle 1257" width="24" height="24" fill="#dfdfdf" opacity="0"/> <g id="Group_124" data-name="Group 124" transform="translate(2 2)"> <path id="price-high" d="M1.893,11.344A1.782,1.782,0,0,1,0,9.7V1.64A1.782,1.782,0,0,1,1.893,0,1.782,1.782,0,0,1,3.787,1.64V9.7A1.782,1.782,0,0,1,1.893,11.344Zm.87-9.7a.819.819,0,0,0-.87-.753.819.819,0,0,0-.87.753V9.7a.819.819,0,0,0,.87.753.819.819,0,0,0,.87-.753Z" transform="translate(15.344 6.123) rotate(90)"/> <path id="price-high-2" data-name="price-high" d="M1.893,8.1A1.77,1.77,0,0,1,0,6.5V1.608A1.77,1.77,0,0,1,1.893,0,1.77,1.77,0,0,1,3.787,1.608V6.5A1.77,1.77,0,0,1,1.893,8.1Zm.87-6.5a.813.813,0,0,0-.87-.739.813.813,0,0,0-.87.739V6.5a.813.813,0,0,0,.87.739.813.813,0,0,0,.87-.739Z" transform="translate(15.344 10.969) rotate(90)"/> <path id="Path_118" data-name="Path 118" d="M2679.957,990.25v12.965" transform="translate(-2662.957 -986.25)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1.3"/> </g> </g> </svg>
											<p className="mb-0">Right</p>
										</button>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</li>
				<li className="nav-item">
					<a className="nav-link" href="/#" id="ic_Opacity" data-toggle="tooltip" title="Opacity">
						<svg id="ic_Opacity" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"> <rect id="Rectangle_1282" data-name="Rectangle 1282" width="36" height="36" opacity="0"/> <g id="Group_134" data-name="Group 134" transform="translate(9 12)"> <g id="color_picker" data-name="color picker"> <path id="Path_100" data-name="Path 100" d="M17.47,22.556a4.331,4.331,0,0,0-2.187-1.529.716.716,0,1,0-.415,1.371A2.884,2.884,0,0,1,16.9,25.14v1.719a2.868,2.868,0,0,1-2.865,2.865H4.3a2.868,2.868,0,0,1-2.865-2.865V25.14A2.868,2.868,0,0,1,4.3,22.276H6.589a.716.716,0,1,0,0-1.432H4.3A4.3,4.3,0,0,0,0,25.14v1.719a4.3,4.3,0,0,0,4.3,4.3h9.74a4.3,4.3,0,0,0,4.3-4.3V25.14a4.26,4.26,0,0,0-.864-2.584Z" transform="translate(0 -20.843)"/> </g> <path id="Path_1328" data-name="Path 1328" d="M26.949,35.825a.9.9,0,1,1-.9.9A.9.9,0,0,1,26.949,35.825Z" transform="translate(-16.028 -33.138)" opacity="0.3"/> <path id="Path_1334" data-name="Path 1334" d="M26.949,35.825a.9.9,0,1,1-.9.9A.9.9,0,0,1,26.949,35.825Z" transform="translate(-16.028 -30.06)" opacity="0.3"/> <path id="Path_1331" data-name="Path 1331" d="M26.949,35.825a.9.9,0,1,1-.9.9A.9.9,0,0,1,26.949,35.825Z" transform="translate(-22.955 -33.138)"/> <path id="Path_1333" data-name="Path 1333" d="M26.949,35.825a.9.9,0,1,1-.9.9A.9.9,0,0,1,26.949,35.825Z" transform="translate(-22.955 -30.06)"/> <path id="Path_1329" data-name="Path 1329" d="M26.949,35.825a.9.9,0,1,1-.9.9A.9.9,0,0,1,26.949,35.825Z" transform="translate(-12.565 -33.138)" opacity="0.1"/> <path id="Path_1335" data-name="Path 1335" d="M26.949,35.825a.9.9,0,1,1-.9.9A.9.9,0,0,1,26.949,35.825Z" transform="translate(-12.565 -30.06)" opacity="0.1"/> <path id="Path_1330" data-name="Path 1330" d="M26.949,35.825a.9.9,0,1,1-.9.9A.9.9,0,0,1,26.949,35.825Z" transform="translate(-19.491 -33.138)" opacity="0.7"/> <path id="Path_1332" data-name="Path 1332" d="M26.949,35.825a.9.9,0,1,1-.9.9A.9.9,0,0,1,26.949,35.825Z" transform="translate(-19.491 -30.06)" opacity="0.7"/> </g> </svg>
					</a>
				</li>
			</ul>

			<ul className="nav-right-side list-inline d-flex align-items-center mb-0">
				<li className="fs-14">Need professional design?</li>
				<li>
					<button className="btn">
						<ul className="d-flex btn-hire">
							<li>
								<svg id="ic_HireDesigner" xmlns="http://www.w3.org/2000/svg" width="28.238" height="23.967" viewBox="0 0 28.238 23.967"> <path id="Path_1536" data-name="Path 1536" d="M2731.054-474.366,2717-491.927l6.488-6.406h15.043l6.706,6.406Z" transform="translate(-2717 498.333)" fill="#fcc24d"/> <path id="Path_1538" data-name="Path 1538" d="M2753.5-498.333l7.552,23.953-14.047-17.562Z" transform="translate(-2746.99 498.333)" fill="#d4a037"/> <path id="Polygon_3" data-name="Polygon 3" d="M4.239.285a.1.1,0,0,1,.19,0L5.4,3.2a.1.1,0,0,0,.063.063l2.918.973a.1.1,0,0,1,0,.19L5.464,5.4a.1.1,0,0,0-.063.063L4.429,8.383a.1.1,0,0,1-.19,0L3.266,5.464A.1.1,0,0,0,3.2,5.4L.285,4.429a.1.1,0,0,1,0-.19L3.2,3.266A.1.1,0,0,0,3.266,3.2Z" transform="translate(15.172 2.05)" fill="#fff"/> </svg>
							</li>
							<li>
								Hire a designer  @ $123.12
							</li>
						</ul>
					</button>
				</li>
				<li>$123.12</li>
				<li>
					<button className="btn btn-cart">
						<svg id="ic_Cart" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"> <rect id="Rectangle_1458" data-name="Rectangle 1458" width="36" height="36" fill="#c9d6de" opacity="0"/> <g id="cart-" transform="translate(8 -10.708)"> <path id="Path_1482" data-name="Path 1482" d="M19.648,22.885a.57.57,0,0,0-.444-.213H5.359A6.212,6.212,0,0,0,.626,19.71a.57.57,0,1,0-.111,1.135c.177.018,4.333.471,4.333,4.471,0,.528.005,1.051.022,1.566,0,0,0,.008,0,.012s0,.014,0,.021c.083,2.516.46,4.806,2.025,6.327a5.212,5.212,0,0,0,1.114.822,2.046,2.046,0,1,0,2.953.82c.317.027.645.041.989.041.133,0,.269,0,.406-.006H13.57a2.044,2.044,0,1,0,3.616,0h.307a.57.57,0,0,0,0-1.141h-5.15c-2.129.062-3.65-.382-4.65-1.354a4.364,4.364,0,0,1-.908-1.308h10.8a.57.57,0,0,0,.557-.447l1.616-7.3A.571.571,0,0,0,19.648,22.885ZM9.126,36.677a.9.9,0,1,1,.9-.9A.9.9,0,0,1,9.126,36.677Zm6.251.1a.9.9,0,1,1,.9-.9A.9.9,0,0,1,15.378,36.772Zm3.115-12.959-.556,2.511H6q-.009-.5-.008-1.007a5.961,5.961,0,0,0-.186-1.5H18.493Zm-1.364,6.162H6.386a13.13,13.13,0,0,1-.349-2.511H17.685Z" transform="translate(0 0)"/> </g> </svg>
						<span className="btn-cart_1">2</span>
					</button>
				</li>
				<li>
					<button className="btn btn-orange">Done</button>
				</li>
			</ul>
		</div>
	</nav>

      </Fragment>
    );
  };
  

  
function mapStateToProps(state) {
    return {
        videos: state.canvas.videos,
        canvas: state.canvas,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            exportToSvg: exportToSvg,
			generatePriveiew: generatePriveiew,
			ungroup: ungroup,
			toGroup:toGroup
        },
        dispatch
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(Header)
