import React, { useEffect } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//import "./App.scss";
import { history } from "./helpers";
import { alertActions } from "./store/actions";
//import { PrivateRoute } from "./utils";
import Login from "./pages/Login/Login";
// import Layout from "./components/Layout/Layout";
// import Dashboard from "./pages/Dashboard/Dashboard";

// import Users from "./pages/Users/Users";
// import UserAddForm from "./pages/Users/UserAddForm";
// import UserEditForm from "./pages/Users/UserEditForm";

// import Library from "./pages/Library/Library";
// import LibraryAddForm from "./pages/Library/LibraryAddForm";
// import LibraryEditForm from "./pages/Library/LibraryEditForm";
 import Configurator from './plugins/configurator/index';

// import Template from "./pages/Template/Template";
// import TemplateAddForm from "./pages/Template/TemplateAddForm";
// import TemplateEditForm from "./pages/Template/TemplateEditForm";

function App() {
  const alert = useSelector((state) => state.alert);
  const dispatch = useDispatch();

  useEffect(() => {
    history.listen((location, action) => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });
  }, [dispatch]);

  return (
    <div>
      {alert.message && (
        <div className={`alert ${alert.type}`}>{alert.message}</div>
      )}
      <Router history={history}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/" component={Configurator} />
          {/* <Layout>
            <Route exact path="/" component={Dashboard} />
            <Route path="/dashboard" component={Dashboard} />

              <Route exact path="/users" component={Users} />
              <Route path="/user/add" component={UserAddForm} />
              <Route path="/user/edit/:id" component={UserEditForm} />

              <Route exact path="/library" component={Library} />
              <Route path="/library/add" component={LibraryAddForm} />
              <Route path="/library/edit/:id" component={LibraryEditForm} />

              <Route exact path="/template" component={Template} />
              <Route path="/template/add" component={TemplateAddForm} />
              <Route path="/template/edit/:id" component={TemplateEditForm} />
            </Layout> */}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
