import fabric from '../fabric'

fabric.fabric.Object.NUM_FRACTION_DIGITS = 8

function findById(canvas, id) {
    let item = canvas._objects.filter((item) => item.id===id)
    return item[0]
}
function findOverlayImage(canvas) {
    let item = canvas._objects.filter((item) => item.id==='overlay_group')
    return item[0]
}
function addEvents(editor, dispatch) {
    var isPartiallyOnScreen = false
    var isOnScreen = false
    var outOfPrintableArea = false
    
    // editor.canvas.on('mouse:move', function (event) {
    //    let pointer =  editor.canvas.getPointer(event)
    //    let mData =  editor.canvas.getContext('2d').getImageData(pointer.x, pointer.y, 1, 1).data; 
    //                 if( mData[3] > 0 && mData[3] != 102){
    //                     console.log(pointer,mData)
    //                 }
                
       
    // })
    // editor.canvas.on('mouse:move', function(e) {

    //     // get the current mouse position
    //     var mouse = editor.canvas.getPointer(e.e);
    //     var x = parseInt(mouse.x);
    //     var y = parseInt(mouse.y);
      
    //     let mData =  editor.canvas.getContext('2d').getImageData(x, y, 1, 1).data; 
    //     console.log(mData,x, y)
    // })
    editor.canvas.on('object:moved', function (event) {
        let outOfPrintableArea = false
        // let pointer = editor.canvas.getPointer(event)
        // console.log('pointer', pointer)
        try{
         editor.canvas?.clone(
             (clonedCanvas) => {
                clonedCanvas.discardActiveObject().renderAll()
                let redLine = clonedCanvas.getObjects().filter(item=> item.id == 'red_line')[0];
         
                let {left, top, width, height} = redLine.getBoundingRect()
                
                width = width*2; 
                height = height*2

                let cutLine = findById(clonedCanvas, 'red_line')
                if (cutLine) {
                    if (!event.target.isContainedWithinObject(cutLine)) {
                        for( let i = left; i < width; i ++ ){
                            if(!clonedCanvas.isTargetTransparent(event.target, i, top)){
                                outOfPrintableArea = true
                                break;
                            }
                        }
                        if(!outOfPrintableArea){
                            for( let i = left; i < height; i ++ ){
                                if(!clonedCanvas.isTargetTransparent(event.target, left, i)){
                                    outOfPrintableArea = true
                                    break;
                                }
                            }
                        }
                        if(!outOfPrintableArea){
                            for( let i = left; i < width; i ++ ){
                                if(!clonedCanvas.isTargetTransparent(event.target, i, top+(height/2))){
                                    outOfPrintableArea = true
                                    break;
                                }
                            }
                        }
                        if(!outOfPrintableArea){
                            for( let i = 0; i < height; i ++ ){
                                if(!clonedCanvas.isTargetTransparent(event.target, left+(width/2), i)){
                                    outOfPrintableArea = true
                                    break;
                                }
                            }
                        }

                    }
                }
                // let mData =  clonedCanvas.getContext('2d').getImageData(0, redLine.top-1, clonedCanvas.width, redLine.top-1).data; 
                // for (let i = 0; i < mData.length; i += 3) {
                //     if(mData[i+3] > 0 && mData[i+3]!= 102){
                //         outOfPrintableArea = true 
                //     }

                // }

                // mData =  clonedCanvas.getContext('2d').getImageData(0, redLine.top, redLine.left-1,clonedCanvas.height ).data; 
                // for (let i = 0; i < mData.length; i += 3) {
                //     if(mData[i+3] > 0 && mData[i+3]!= 102){
                //         outOfPrintableArea = true 
                //     }
                // }
                // mData =  clonedCanvas.getContext('2d').getImageData(0, redLine.top+redLine.height, redLine.left-1,clonedCanvas.height ).data; 
                // for (let i = 0; i < mData.length; i += 3) {
                //     if(mData[i+3] > 0 && mData[i+3]!= 102){
                //         outOfPrintableArea = true 
                //     }
                // }

                // console.log((redLine.left+redLine.width+1), redLine.top, redLine.left,clonedCanvas.height )
                // mData =  clonedCanvas.getContext('2d').getImageData((redLine.left+redLine.width+1), redLine.top, redLine.left,clonedCanvas.height ).data; 
                // for (let i = 0; i < mData.length; i += 3) {
                //     if(mData[i+3] > 0 && mData[i+3]!= 102){
                //         outOfPrintableArea = true 
                //     }
                // }

                if(outOfPrintableArea){
                    dispatch({ type: 'OBJECT_OUTOF_PRINTABLE_AREA', payload: true })
                }else{
                    dispatch({ type: 'OBJECT_OUTOF_PRINTABLE_AREA', payload: false })
                }
                
            },['id'])

        }catch(e){
            console.log(e)
        }
        
     });
     
    editor.canvas.on({
        'object:moving': onChange,
        'object:scaling': onChange,
        'object:rotating': onChange,
    })

    function onChange(options) {
        options.target.setCoords()
        let boundings = options.target.getBoundingRect()
        let overlayImage = findOverlayImage(editor.canvas)
        if (typeof overlayImage !=='undefined') {
            if (
                !editor.canvas.isTargetTransparent(
                    overlayImage,
                    boundings.left,
                    boundings.top
                )
            ) {
                dispatch({ type: 'OBJECT_OUTOF_PRINTABLE_AREA', payload: true })
                outOfPrintableArea = true
            } else if (
                !editor.canvas.isTargetTransparent(
                    overlayImage,
                    boundings.left + boundings.width,
                    boundings.top
                )
            ) {
                dispatch({ type: 'OBJECT_OUTOF_PRINTABLE_AREA', payload: true })
                outOfPrintableArea = true
            } else if (
                !editor.canvas.isTargetTransparent(
                    overlayImage,
                    boundings.left,
                    boundings.top + boundings.height
                )
            ) {
                dispatch({ type: 'OBJECT_OUTOF_PRINTABLE_AREA', payload: true })
                outOfPrintableArea = true
            } else if (
                !editor.canvas.isTargetTransparent(
                    overlayImage,
                    boundings.left + boundings.width,
                    boundings.top + boundings.height
                )
            ) {
                dispatch({ type: 'OBJECT_OUTOF_PRINTABLE_AREA', payload: true })
                outOfPrintableArea = true
            } else {
                if (outOfPrintableArea) {
                    dispatch({
                        type: 'OBJECT_OUTOF_PRINTABLE_AREA',
                        payload: false,
                    })
                    outOfPrintableArea = false
                }
            }
        } else {
            let cutLine = findById(editor.canvas, 'red_line')
            // if (cutLine) {
            //     if (!options.target.isContainedWithinObject(cutLine)) {
            //         dispatch({
            //             type: 'OBJECT_OUTOF_PRINTABLE_AREA',
            //             payload: true,
            //         })
            //         outOfPrintableArea = true
            //     } else {
            //         dispatch({
            //             type: 'OBJECT_OUTOF_PRINTABLE_AREA',
            //             payload: false,
            //         })
            //         outOfPrintableArea = false
            //     }
            // }
        }
        editor.canvas.forEachObject(function (obj) {
            if (obj === options.target) return
            if (options.target.isPartiallyOnScreen() !==isPartiallyOnScreen) {
                dispatch({
                    type: 'PARTIAL_OUTOF_CANVAS',
                    payload: options.target.isPartiallyOnScreen(),
                })
                isPartiallyOnScreen = options.target.isPartiallyOnScreen()
            }
            if (options.target.isOnScreen() !==isOnScreen) {
                dispatch({
                    type: 'FULL_OUTOF_CANVAS',
                    payload: options.target.isOnScreen(),
                })
                isOnScreen = options.target.isOnScreen()
            }
        })
    }
}

export function exportToSvg() {
    return function (dispatch) {
        dispatch({
            type: 'EXPORT_SVG',
            payload: null,
        })
    }
}

export function generatePriveiew(canvasStore) {
    return function (dispatch) {
        const canvas = canvasStore.selectedCanvas.canvas

        canvas.clone(
            (clonedCanvas) => {
                //            let clonedCanvas = state.selectedCanvas?.canvas
                //clonedCanvas  =state.selectedCanvas.canvas
                let overlayGroup = clonedCanvas._objects.find(
                    (item) => item.id==='overlay_group'
                )
                if (overlayGroup) {
                    //overlayGroup.ungroupOnCanvas()
                    //  var destroyedGroup = overlayGroup.destroy();
                    var items = overlayGroup.getObjects()

                    clonedCanvas.remove(overlayGroup)

                    items.forEach(function (item) {
                        //item.setCoords()
                        if (item==='overlay_image') {
                            item.fill = 'white'
                            item.bringToFront()
                        }

                        clonedCanvas.add(item)
                    })

                    let overlayRect = clonedCanvas._objects.find(
                        (item) => item.id==='overlay_image'
                    )
                    if (overlayRect) {
                        overlayRect.fill = 'white'
                        // var bg = new fabric.fabric.Image.fromURL('/overlays/pug.jpg',function(item){
                        //     overlayRect = item
                        //  })

                        overlayRect.bringToFront()
                    }
                    clonedCanvas.renderAll()
                    let overlayes = clonedCanvas._objects.filter(
                        (item) => item.id==='overlay_image'
                        //  item.id==='black_line' ||
                        //  item.id==='red_line'
                    )
                    let newGroup = new fabric.fabric.Group(overlayes, {
                        ...overlayGroup.toObject(),
                    })
                    newGroup.scaleX = clonedCanvas.width / newGroup.width
                    newGroup.scaleY = clonedCanvas.height / newGroup.height
                    newGroup.id = 'overlay_group'
                    newGroup.selectable = false
                    newGroup.isControlVisible = false
                    newGroup.hasControls = false
                    newGroup.lockMovementX = true
                    newGroup.lockMovementY = true
                    newGroup.evented = false
                    clonedCanvas.add(newGroup)
                    clonedCanvas._objects
                        .filter(
                            (item) =>
                                item.id==='overlay_image' ||
                                item.id==='black_line' ||
                                item.id==='red_line'
                        )
                        .map((item) => clonedCanvas.remove(item))
                    clonedCanvas.renderAll()
                }
             
               
                let canvasToImage = clonedCanvas.toDataURL('png')
                clonedCanvas.clear()
                clonedCanvas.renderAll()
                new fabric.fabric.util.loadImage(canvasToImage, function (img) {
                    let imgObj = new fabric.fabric.Image(img, {
                        left: 0,
                        top: 0,
                        scaleX: clonedCanvas.width / img.width,
                        scaleY: clonedCanvas.height / img.height,
                    })

                    var filter = new fabric.fabric.Image.filters.RemoveColor({
                        threshold: 0.2,
                    })
                    imgObj.filters.push(filter)
                    imgObj.applyFilters()
                    clonedCanvas.add(imgObj)

                    const previewCanvas = new fabric.fabric.Canvas(
                        'preview_canvas'
                    )

                    previewCanvas.setDimensions({ width: 600, height: 600 })
                    if(typeof canvasStore.canvasOptions.scene != 'undefined'){
                    fabric.fabric.loadSVGFromURL(
                        `/overlays/scenes/${canvasStore.canvasOptions.scene.image}`,
                        (objs, options) => {
                            const group = fabric.fabric.util.groupSVGElements(
                                objs,
                                options
                            )

                            group.set({
                                scaleX: previewCanvas.width / group.width,
                                scaleY: previewCanvas.height / group.height,
                            })
                            group.selectable = false
                            group.isControlVisible = false
                            group.hasControls = false
                            group.lockMovementX = true
                            group.lockMovementY = true
                            group.evented = false

                            let items = group.getObjects()
                            group.destroy()

                            previewCanvas.renderOnAddRemove = false
                            previewCanvas.add.apply(previewCanvas, items)
                            previewCanvas.renderOnAddRemove = true

                            previewCanvas.renderAll()
                            let Cut_path = previewCanvas._objects.find(
                                (item) => item.id==='Cutpath'
                            )
                            let safeArea = previewCanvas._objects.find(
                                (item) => item.id==='Pole'
                            )

                            try {
                                if (Cut_path) {
                                    let hangerImage = safeArea.toDataURL({
                                        type: 'png',
                                    })
                                    new fabric.fabric.util.loadImage(
                                        hangerImage,
                                        function (hangerImg) {
                                            let hangerImageObject = new fabric.fabric.Image(
                                                hangerImg,
                                                {
                                                    left: safeArea.left,
                                                    top: safeArea.top,
                                                    scaleX:
                                                        safeArea.getScaledWidth() /
                                                        hangerImg.width,
                                                    scaleY:
                                                        safeArea.getScaledHeight() /
                                                        hangerImg.height,
                                                }
                                            )
                                            previewCanvas.add(hangerImageObject)
                                            previewCanvas.bringToFront(
                                                hangerImageObject
                                            )
                                            previewCanvas.remove(safeArea)
                                            previewCanvas.renderAll()

                                            // Add main canvas output to preview canvas
                                            new fabric.fabric.util.loadImage(
                                                clonedCanvas.toDataURL('png'),
                                                function (img) {
                                                    let imgObj = new fabric.fabric.Image(
                                                        img,
                                                        {
                                                            left: Cut_path.left,
                                                            top: Cut_path.top,
                                                            scaleX:
                                                                Cut_path.getScaledWidth() /
                                                                img.width,
                                                            scaleY:
                                                                Cut_path.getScaledHeight() /
                                                                img.height,
                                                        }
                                                    )

                                                    imgObj.selectable = false
                                                    imgObj.isControlVisible = false
                                                    imgObj.hasControls = false
                                                    imgObj.lockMovementX = true
                                                    imgObj.lockMovementY = true
                                                    imgObj.evented = false

                                                    // let blendFilter = new fabric.fabric.Image.filters.BlendImage({
                                                    //     image: imgObj,
                                                    //     mode: 'multiply',
                                                    //      alpha: 1
                                                    //   });
                                                    //   hangerImageObject.filters.push(blendFilter);
                                                    //   hangerImageObject.applyFilters();

                                                    previewCanvas.add(imgObj)
                                                    //imgObj.setCoords()
                                                    previewCanvas.bringToFront(
                                                        hangerImageObject
                                                    )
                                                    previewCanvas.remove(
                                                        Cut_path
                                                    )

                                                    previewCanvas._objects.map(
                                                        (item) => {
                                                            item.selectable = false
                                                            item.isControlVisible = false
                                                            item.hasControls = false
                                                            item.lockMovementX = true
                                                            item.lockMovementY = true
                                                            item.evented = false
                                                        }
                                                    )

                                                    previewCanvas.renderAll()
                                                }
                                            )
                                        }
                                    )
                                }else{
                                    throw("Cut path not found")
                                }
                            } catch (error) {
                                console.log("Error In preview",error)
                            }
                            previewCanvas.renderAll()
                        }
                    )
                    }
                    clonedCanvas.renderAll()

                    dispatch({
                        type: 'UPDATE_PREVIEW',
                        payload: clonedCanvas.toDataURL('png'),
                    })
                })
            },
            ['id']
        )
    }
}

function canvasResize(editor, canvasOptions) {
//    var canvasSizer = canvasOptions.container.current
    var width = canvasOptions.width 
    var height = canvasOptions.height 
   
    // var ratio = (canvasOptions.width / canvasOptions.height)
    // if (width / height > ratio) {
    //     width = height * ratio
    // } else {
    //     height = width / ratio
    // }
    // console.log('canvasOptions',canvasOptions, ratio)
    // console.log(width, height)
    // var scale =   canvasOptions.width/width
    // var zoom = editor.canvas.getZoom()
    // zoom = zoom*scale
    let safePixels = 0
    if (
        typeof canvasOptions.overlay==='undefined' ||
        canvasOptions.overlay===null
    ) {
        if (canvasOptions.oWidth > 6 || canvasOptions.height > 6) {
            safePixels = 96 
        } else {
            safePixels = 48 
        }

        var rect = new fabric.fabric.Rect({
            left: safePixels / 2,
            top: safePixels / 2,
            fill: '',
            id: 'red_line',
            stroke: 'red',
            strokeDashOffset: 3,
            strokeDashArray: [4],
            strokeWidth: 2,
            width: parseFloat(width - safePixels),
            height: parseFloat(height - safePixels),
        })

        rect.selectable = false
        rect.isControlVisible = false
        rect.hasControls = false
        rect.lockMovementX = true
        rect.lockMovementY = true
        rect.evented = false
        // "add" rectangle onto canvas
        editor.canvas.add(rect)
        rect.setCoords()
        editor.canvas.renderAll()
    }
   // editor.canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0])
    editor.canvas.setDimensions({
        width: width,
        height: height,
    })
}

export function canvasInit(editor, canvasOptions = {}) {
    return function (dispatch) {
        editor.canvas.preserveObjectStacking = true
        addEvents(editor, dispatch)
        dispatch({
            type: 'SHOW',
            text: 'Intializing...',
        })
        let pixalPerInch = 96
        canvasOptions.oWidth = canvasOptions.width
        canvasOptions.oHeight = canvasOptions.height
        canvasOptions.width = canvasOptions.width * pixalPerInch
        canvasOptions.height = canvasOptions.height * pixalPerInch
        //canvasResize(editor, canvasOptions)
        if (
            typeof canvasOptions.overlay !=='undefined' &&
            canvasOptions.overlay
        ) {
            canvasResize(editor, canvasOptions)
            fabric.fabric.loadSVGFromURL(
                canvasOptions.overlay,
                (objs, options) => {
                    const group = fabric.fabric.util.groupSVGElements(
                        objs,
                        options
                    )
                    group.set({
                        scaleX: editor.canvas.width / group.width,
                        scaleY: editor.canvas.height / group.height,
                    })
                    group.id = 'overlay_group'
                    group.selectable = false
                    group.isControlVisible = false
                    group.hasControls = false
                    group.lockMovementX = true
                    group.lockMovementY = true
                    group.evented = false
                    editor.canvas.add(group)
                }
            )
        } else {
            canvasResize(editor, canvasOptions)
        }
        editor.canvas.renderAll()
        dispatch({
            type: 'INIT',
            text: 'Intializing...',
            payload: editor,
            options: canvasOptions,
        })

        dispatch({
            type: 'HIDE',
            text: 'Intializing...',
        })
    }
}

export function addTriAngle() {
    return function (dispatch) {
        dispatch({
            type: 'ADD_TRIANGLE',
        })
    }
}

export function addCircle() {
    return function (dispatch) {
        dispatch({
            type: 'ADD_CIRCLE',
        })
    }
}

export function addRect() {
    return function (dispatch) {
        dispatch({
            type: 'ADD_RECT',
        })
    }
}

export function disposeCanvas(editor) {
    return editor.canvas.dispose()
}

export function uploadSvgTemplate(file) {
    return function (dispatch) {
        dispatch({
            type: 'ADD_SVG_TEMPLATE',
            payload: file,
        })
        setTimeout(()=>{
            dispatch({
                type: 'UPDATE_LAYERS'
            })
        }, 3000)

    }
}

export function addImage(imageBase64) {
    return function (dispatch) {
        dispatch({
            type: 'ADD_IMAGE',
            payload: imageBase64,
        })
    }
}

export function removeObject(object) {
    return function (dispatch) {
        dispatch({
            type: 'REMOVE_OBJECT',
            payload: object,
        })
    }
}

export function bringBackToCenter(object) {
    return function (dispatch) {
        dispatch({
            type: 'BRING_CENTER_OBJECT',
            payload: object,
        })
    }
}

export function changeProduct(product) {
    return function (dispatch) {
        dispatch({
            type: 'PRODUCT_LOAD',
            payload: product,
        })
    }
}

export function getObjects() {
    return function (dispatch) {

        
    }
}


export function toGroup(objects) {
    return function (dispatch) {
        dispatch({
            type: 'GROUP',
            payload: objects,
        })
        setTimeout(()=>{
            dispatch({
                type: 'UPDATE_LAYERS'
            })
        }, 1000)
        
    }
}

export function ungroup(objects) {
    return function (dispatch) {
        dispatch({
            type: 'UNGROUP',
            payload: objects,
        })
        setTimeout(()=>{
            dispatch({
                type: 'UPDATE_LAYERS'
            })
        }, 1000)

    }
}